import React from "react";
import Main from "./components/Main";
import auth from "../src/components/auth.js";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { enableRipple } from "@syncfusion/ej2-base";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import LocalStorageService from "./services/localStorageService";

import { deleteCookie, getCookie } from "./services/cookieService";

enableRipple(true);
const localStorageService = new LocalStorageService();

class App extends Component {
  state = {
    loginstat: false,

    items: [
      {
        text: "Terms and Conditions",
      },
      {
        text: "Logout",
      },
    ],
  };
  cookie = getCookie();

  logout = () => {
    this.setState({
      loginstat: false,
    });
    localStorageService.clearToken();
    localStorage.removeItem("WorkFlowId");
    localStorageService.clearConfToken();
    localStorageService.clearIsAuth();
    localStorageService.clearKNC();
    localStorageService.clearPathName();
    deleteCookie();

    auth.logout(() => {
      this.props.history.push("/");
    });
  };

  switchFunc = () => {
    this.setState({
      loginstat: true,
    });
  };

  here = (e) => {
    if (e.item.text === "Logout") {
      this.logout();
    } else if (e.item.text === "Terms and Conditions") {
      window
        .open(
          "https://www.workhealthyaustralia.com.au/privacy-policy/",
          "_blank"
        )
        .focus();
    }
  };

  componentDidMount() {
    if (this.cookie && this.cookie.access_token) {
      console.log(this.cookie);

      localStorageService.setIsAuth(true);
    } else {
      localStorageService.clearIsAuth();
    }
  }

  render() {
    return (
      <div>
        <div>
          <a href="/Splashscreen">
            <img
              className="wha-logo"
              src={require("../src/components/WHAlogonew.png")}
              alt=""
            />
          </a>
          {this.state.loginstat || (this.cookie && this.cookie.access_token) ? (
            <DropDownButtonComponent
              style={{
                float: "right",
                marginTop: "26px",
                marginRight: "17px",
              }}
              items={this.state.items}
              select={this.here}
              iconCss="e-icons e-image"
              cssClass="e-caret-hide"
            />
          ) : null}
        </div>

        <hr className="top-horizontal-line"></hr>

        <Main
          state={this.state}
          switchFunc={this.switchFunc}
          logout={this.logout}
        />
      </div>
    );
  }
}

export default withRouter(App);
