import React, { Component } from "react";
import auth from "./auth";
import { Redirect, Link, withRouter } from "react-router-dom";

import Landingpage from "./Landingpage";
import { createSignIn, getUserAuth } from "../services/api";
import LocalStorageService from "../services/localStorageService";
import { encrypt } from "../util/crypto";
import { createCookie, getCookie } from "../services/cookieService";

class Loginpage extends Component {
  cookie = getCookie();
  state = {
    loadingCircle: false,
    userEmPh: "",
    pass: "",
    usernameErr: "",
    usernameErrvalid: false,
    passwordErr: "",
    passwordErrvalid: false,
    authErr: "",
    showLogin: false,
  };
  localStorageService = new LocalStorageService();

  returnedUserOp = (data) => {
    console.log(data);
    this.setState({ authErr: "" });
    this.setState({ loadingCircle: false });

    auth.login(() => {
      // this.props.history.push("/Home");
      this.props.history.push("/Splashscreen");
    });
  };

  authenticate = (e) => {
    console.log(e);

    this.localStorageService.setToken(e);
    createCookie(e);

    this.localStorageService.setIsAuth(true);

    getUserAuth()
      .then(({ data }) => this.returnedUserOp(data))
      .catch((error) => {
        this.setState({ authErr: "login failed" });
        this.setState({ loadingCircle: false });
      });
  };

  changeLoadingCircle = () => {
    this.setState({ loadingCircle: true });
    return true;
  };
  continue = (e) => {
    e.preventDefault();
    this.setState({ authErr: "" });
    const isValid = this.loginVAlidation(e);
    const passVal = this.passVal(e);
    if (isValid && passVal) {
      this.changeLoadingCircle();

      console.log("logging in");

      const schema = {
        schema: {
          Email: this.state.userEmPh,
          Password: encrypt(this.state.pass),
        },
      };

      createSignIn(schema.schema)
        .then((response) => this.authenticate(response.data))
        .catch((error) => {
          if (error.response.data === "Incorrect username or password.") {
            this.setState({ authErr: "Incorrect email address or password." });
          } else {
            this.setState({ authErr: error.response.data });
          }
          this.setState({ loadingCircle: false });
        });
    }
  };
  passVal = (e) => {
    if (this.state.pass.length === 0) {
      this.setState({
        passwordErr: "Please enter your password",
      });
      return false;
    } else {
      this.setState({ passwordErrvalid: true, passwordErr: "" });
      return true;
    }
  };
  loginVAlidation = (e) => {
    var phno = /[+]\d{11}$/;
    var em = /^\S+@\S+\.\S+$/;
    if (
      (this.state.userEmPh.match(phno) &&
        this.state.userEmPh.toString().startsWith("+61")) ||
      this.state.userEmPh.match(em)
    ) {
      this.setState({ usernameErrvalid: true, usernameErr: "" });
      return true;
    } else if (this.state.userEmPh === "") {
      this.setState({
        usernameErr: "Required field",
      });
      return false;
    } else {
      this.setState({
        usernameErr: "Invalid input",
      });
      return false;
    }
  };

  loginswitch = () => {
    this.setState({ showLogin: true });
  };

  render() {
    const { loadingCircle } = this.props;
    const search = this.props.location.search;
    const pathname = this.props.location.pathname;
    const code = new URLSearchParams(search).get("code");
    this.localStorageService.setOnboardCode(code);
    this.localStorageService.setPathName(pathname);
    if (!this.cookie) {
      return (
        <div>
          {this.state.showLogin ? (
            <div id="MainDiv">
              {this.state.loadingCircle === true ? loadingCircle : null}

              <div className="page-title lg">
                <div className="title">
                  <h1>Welcome to Your Health Manager Provider Testing</h1>
                </div>
              </div>
              <div className="contentSpacing">
                <div></div>
                <div className="landing-page-heading">
                  <img src={require("./WHAlogonew.png")} />
                </div>
                <div className="row has-form login-page-login">
                  <div>
                    <div className="form-group">
                      <label className="abc">Email</label>
                      <input
                        className="form-control"
                        id="userEmPh"
                        name="userEmPh"
                        type="text"
                        value={this.state.userEmPh}
                        onChange={(event) =>
                          this.setState({ userEmPh: event.target.value })
                        }
                      />
                      <div className="errorMessage">
                        {this.state.usernameErr}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="form-group">
                      <label className="abc">Password</label>
                      <input
                        className="form-control"
                        id="pass"
                        name="pass"
                        type="password"
                        value={this.state.pass}
                        onChange={(event) =>
                          this.setState({ pass: event.target.value })
                        }
                      />
                      <div className="errorMessage">
                        {this.state.passwordErr}
                      </div>
                      <div className="errorMessage">{this.state.authErr}</div>
                    </div>
                    <div className="btn-block">
                      <button
                        className="btn btn-primary btn-block"
                        onClick={this.continue}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                  <div className="forg">
                    <Link className="passwordForgot" to="/ForgotPassword">
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="forg">
                    <Link to="/Signup">Create your own account</Link>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Landingpage loginswitch={this.loginswitch}></Landingpage>
          )}
        </div>
      );
    } else {
      auth.login(() => {
        this.props.history.push("/Home");
      });
      return <Redirect to="/Home" push />;
    }
  }
}
export default withRouter(Loginpage);
