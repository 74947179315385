/* This file wraps all API calls and exposes the basic functionality to other pages

accepted syntax for post: export cont functionNAme = (body) => _axios.post(URL, body)
accepted syntax for get: export cont functionNAme = _axios.get(URL)
*/

import auth from "../components/auth";
import { WFE_MODULE, WFE_PROCESS } from "./constants";
import Service from "./index";
import {
  PERSONAL_DETAILS,
  REGISTER,
  WORK_FLOW_NEW_REG,
  USER_AUTH,
  SIGN_IN,
  WORKFLOW,
  FORGOT_PASSWORD,
  CONFIRM_PASSWORD,
  ETHNICITY,
  COUNTRY,
  POBdetails,
  SAVE_WORKFLOW,
  SIGNUP,
  NAME_DETAILS,
  ADDRESS_DETAILS,
  ATTRIBUTE_DETAILS,
  NEXT_WORKFLOW,
  CONTACT_DETAILS,
  JOB_DETAILS,
  MED_DETAILS,
  SUPP_DETAILS,
  MEDHISTORY_DETAILS,
  CORE_MEDHISTORY_DETAILS,
  FAMILY_MEDHISTORY_DETAILS,
  LIFESTYLE_MEDHISTORY_DETAILS,
  ADDITIONAL_MEDHISTORY_DETAILS,
  WORK_RELATED_MEDHISTORY_DETAILS,
  MEDLIST_INSERT,
  PES_DETAILS,
  PES_MEDHISTORY_DETAILS,
  PES_FOOD_QUESTIONNAIRE_DETAILS,
  POBSymptomsdetails,
  RETURN_PATIENT_NEW_INURY,
  EXISTING_ENTRIES,
  REVIEW_WORKFLOW,
  REVIEW_PERSONAL_DETAILS,
  INCOMPLETE_WORKFLOW,
  RETURN_PATIENT_NEW_PES,
} from "./url";

const _axios = new Service();

export const createPersonalDetails = (body) =>
  _axios.post(PERSONAL_DETAILS, body);
export const createRegister = (body) => _axios.post(REGISTER, body);
export const createWorkFlowNewReg = (body) =>
  _axios.post(WORK_FLOW_NEW_REG, body);
export const getUserAuth = () => _axios.get(USER_AUTH);
export const createSignIn = (body) => _axios.post(SIGN_IN, body);
export const getWorkFlow = (params) => _axios.get(WORKFLOW, params);
export const createWorkFlow = (body) => _axios.post(WORKFLOW, body);
export const createForgotPassword = (body) =>
  _axios.post(FORGOT_PASSWORD, body);
export const createConfirmPassword = (body) =>
  _axios.post(CONFIRM_PASSWORD, body);
export const getEthnicity = () => _axios.get(ETHNICITY);
export const getPersonalDetails = (params) =>
  _axios.get(PERSONAL_DETAILS, params);
export const getCountry = () => _axios.get(COUNTRY);
export const getPOBDetails = (params) => _axios.get(POBdetails, params);
export const deletePOBDetails = (params) => _axios.delete(POBdetails, params);
export const createPOBDetails = (body) => _axios.post(POBdetails, body);
export const createSaveWorkflow = (body) => _axios.post(SAVE_WORKFLOW, body);
export const createSignUpPatient = (body) => _axios.post(SIGNUP, body);
export const savePersonalDetails = (body) =>
  _axios.post(PERSONAL_DETAILS, body);
export const saveContactDetails = (body) => _axios.post(CONTACT_DETAILS, body);
export const savePersonAttributesDetails = (body) =>
  _axios.post(ATTRIBUTE_DETAILS, body);
export const getNextWorkFlowDetails = (params) =>
  _axios.get(NEXT_WORKFLOW, params);
export const saveJobDetails = (body) => _axios.post(JOB_DETAILS, body);
export const getMedDetails = () => _axios.get(MED_DETAILS);
export const getSuppDetails = () => _axios.get(SUPP_DETAILS);
export const getMedicalHistoryDetails = (params) =>
  _axios.get(MEDHISTORY_DETAILS, params);
export const getMedListInsertDetails = (params) =>
  _axios.get(MEDLIST_INSERT, params);
export const saveMedicalHistoryDetails = (body) =>
  _axios.post(MEDHISTORY_DETAILS, body);
export const saveCoreMedicalHistoryDetails = (body) =>
  _axios.post(CORE_MEDHISTORY_DETAILS, body);
export const deleteMedicalHistoryDetails = (body) =>
  _axios.delete(CORE_MEDHISTORY_DETAILS, body);
export const saveFamilyMedicalHistoryDetails = (body) =>
  _axios.post(FAMILY_MEDHISTORY_DETAILS, body);
export const saveLifestyleHistoryDetails = (body) =>
  _axios.post(LIFESTYLE_MEDHISTORY_DETAILS, body);
export const saveAdditonalMedicalHistoryDetails = (body) =>
  _axios.post(ADDITIONAL_MEDHISTORY_DETAILS, body);
export const saveWorkRelatedDetails = (body) =>
  _axios.post(WORK_RELATED_MEDHISTORY_DETAILS, body);
export const saveMedListInsertDetails = (body) =>
  _axios.post(MEDLIST_INSERT, body);
export const deleteMedListInsertDetails = (params) =>
  _axios.get(MEDLIST_INSERT, params);
export const getPESDetails = (params) => _axios.get(PES_DETAILS, params);
export const savePESMedHistoryDetails = (body) =>
  _axios.post(PES_MEDHISTORY_DETAILS, body);
export const savePESFoodQuestionnaireDetails = (body) =>
  _axios.post(PES_FOOD_QUESTIONNAIRE_DETAILS, body);
export const getInjuryComplaintDetails = (params) =>
  _axios.get(POBdetails, params);
export const createInjuryComplaintDetails = (body) =>
  _axios.post(POBdetails, body);
export const createInjuryComplaintSymptomsDetails = (body) =>
  _axios.post(POBSymptomsdetails, body);
export const createNewInjuryWorkflow = (body) =>
  _axios.post(RETURN_PATIENT_NEW_INURY, body);
export const getExistingEntries = (params) =>
  _axios.get(EXISTING_ENTRIES, params);
export const getReviewWorkflow = (params) =>
  _axios.get(REVIEW_WORKFLOW, params);
export const reviewePersonalDetails = (body) =>
  _axios.post(REVIEW_PERSONAL_DETAILS, body);
export const getIncompleteWorkFlow = (params) =>
  _axios.get(INCOMPLETE_WORKFLOW, params);
export const createNewPESWorkflow = (body) =>
  _axios.post(RETURN_PATIENT_NEW_PES, body);
/*
  220531 VG: PPV3 - function to direct user straight to the page that needs to be filled. User can review it once they complete the full workflow.
  There is no option for back button. The first workflow from todoList gets assigned to CurrentForm. The CurrentForm comes as currentWorkflow in the showPage Function
  
  */
export const showPage = (currentWorkflow, props) => {
  if (
    currentWorkflow["wfeModuleID"] === WFE_MODULE.PERSONAL_DETAILS &&
    currentWorkflow["dateCompleted"] === null
  ) {
    auth.login(() => {
      props.history.push({
        pathname: "/patientDetails",
        state: { workflow: currentWorkflow },
      });
    });
  } else if (
    currentWorkflow["wfeModuleID"] === WFE_MODULE.INJURY_DETAILS &&
    (currentWorkflow["wfeProcessID"] === WFE_PROCESS.NEW_CARE_PLAN ||
      currentWorkflow["wfeProcessID"] ===
        WFE_PROCESS.NEW_PATIENT_WITH_INJURY) &&
    currentWorkflow["dateCompleted"] === null
  ) {
    auth.login(() => {
      props.history.push({
        pathname: "/painIndicator",
        state: { workflow: currentWorkflow },
      });
    });
  } else if (
    (currentWorkflow["wfeModuleID"] === WFE_MODULE.CORE_MEDICAL_HISTORY ||
      currentWorkflow["wfeModuleID"] ===
        WFE_MODULE.FAMILY_HISTORY_MEDICAL_HISTORY ||
      currentWorkflow["wfeModuleID"] === WFE_MODULE.LIFESTYLE_MEDICAL_HISTORY ||
      currentWorkflow["wfeModuleID"] === WFE_MODULE.RED_FLAG_MEDICAL_HISTORY ||
      currentWorkflow["wfeModuleID"] === WFE_MODULE.INDUSTRY_SPECIFIC_SCREEN) &&
    currentWorkflow["dateCompleted"] === null
  ) {
    console.log(currentWorkflow);
    auth.login(() => {
      props.history.push({
        pathname: "/coreMedicalHistory",
        state: { workflow: currentWorkflow },
      });
    });
  } else if (
    currentWorkflow["wfeModuleID"] === WFE_MODULE.JOB_HISTORY_DETAILS &&
    (currentWorkflow["wfeProcessID"] === WFE_PROCESS.NEW_PES ||
      currentWorkflow["wfeProcessID"] === WFE_PROCESS.PES_CANDIDATE) &&
    currentWorkflow["dateCompleted"] === null
  ) {
    auth.login(() => {
      props.history.push({
        pathname: "/pesJob",
        state: { workflow: currentWorkflow },
      });
    });
  } else if (
    currentWorkflow["wfeModuleID"] === WFE_MODULE.FOOD_HANDLER &&
    currentWorkflow["dateCompleted"] === null
  ) {
    auth.login(() => {
      props.history.push({
        pathname: "/pesFoodQ",
        state: { workflow: currentWorkflow },
      });
    });
  } else if (
    currentWorkflow["parentEntityID"] === null &&
    currentWorkflow["parentEntityType"] === null
  ) {
    auth.login(() => {
      props.history.push({
        pathname: "/splashscreen",
      });
    });
  } else {
    auth.login(() => {
      props.history.push({
        pathname: "/Home",
        state: { workflow: currentWorkflow },
      });
    });
  }
};
