import React, { Component } from "react";
//import { format } from "date-fns";
import auth from "../auth";
import "../../App.css";
import { DEFAULT_DATE, WFE_MODULE } from "../../services/constants";
import { getCookie, getKNC } from "../../services/cookieService";
import {
  saveJobDetails,
  getMedicalHistoryDetails,
  getPersonalDetails,
  getPESDetails,
  savePESMedHistoryDetails,
  savePESFoodQuestionnaireDetails,
  getNextWorkFlowDetails,
  showPage,
} from "../../services/api";
import { RadioGroup } from "react-radio-group";
import ProgressBar from "react-bootstrap/ProgressBar";
class PESJobDetails extends Component {
  cookie = getCookie();
  currentYear = new Date().getFullYear();
  state = {
    PreviousJob: "",
    previousJobError: "",
    previousJobisValid: true,
    LastEmpDate: "",
    LastEmpDateError: "",
    LastEmpDateisValid: true,
    Employer: "",
    employerError: "",
    employerisValid: true,
    LengthYears: "",
    LengthYearsError: "",
    LengthYearsisValid: true,
    LengthMonths: "",
    LengthMonthsError: "",
    LengthMonthsisValid: true,
    medical_conditions_effecting_test: "",
    medical_conditions_effecting_test_reason: "",
    UUID: "",
    POBPatientID: "",
    id: "",
    submit: false,
    apply_for: "",
    diarrhoea: "",
    skin_disorder: "",
    skin_disorder_details: "",
    weeks_four: "",
    weeks_four_details: "",
    weeks_thirteen: "",
    weeks_thirteen_details: "",
    workflow: this.props?.history?.location?.state?.workflow
      ? this.props.history.location.state.workflow
      : [],
    nextWorkflow: [],
    workflow_review: [],
    nextWorkflow_review: [],
    loadingCircle: false,
  };
  back = (e) => {
    e.preventDefault();
    if (this.state.workflow["dateCompleted"] === null) {
      this.props.history.push("/coreMedicalHistory");
    } else {
      auth.login(() => {
        this.props.history.push("/");
      });
    }
  };
  dateFormatter = (date) => {
    var d = new Date(new Date(Date.parse(date)).toDateString()),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }

    return [year, month, day].join("-");
  };
  componentDidMount() {
    getMedicalHistoryDetails({
      value: getKNC(this.cookie.access_token),
      parententityid: this.state.workflow["parentEntityID"],
      parententitytype: this.state.workflow["parentEntityType"],
    })
      .then(({ data }) => {
        var medical_history_details = data["generalMedHistory"][0];
        this.setState({
          //core medical history
          id: medical_history_details.pobMedHistoryID,
          POBPatientID: medical_history_details.pobPatientID,
          UUID: getKNC(this.cookie.access_token),
          medical_conditions_effecting_test:
            medical_history_details.medicalConditionsEffectingTest.includes("|")
              ? medical_history_details.medicalConditionsEffectingTest.split(
                  "|"
                )[0]
              : medical_history_details.medicalConditionsEffectingTest,
          medical_conditions_effecting_test_reason:
            medical_history_details.medicalConditionsEffectingTest.includes("|")
              ? medical_history_details.medicalConditionsEffectingTest.split(
                  "|"
                )[1]
              : "",
        });
      })
      .catch((error) => {
        console.log(error);
      });
    getPersonalDetails({ value: getKNC(this.cookie.access_token) })
      .then(({ data }) => {
        var job_details = data[2];
        if (job_details.length) {
          this.setState({
            PreviousJob: job_details[0].description
              ? job_details[0].description
              : this.state.PreviousJob,
            Employer: job_details[0].employer
              ? job_details[0].employer
              : this.state.Employer,
            LengthMonths: job_details[0].lengthInMonths
              ? job_details[0].lengthInMonths % 12
              : this.state.LengthMonths,
            LengthYears: job_details[0].lengthInMonths
              ? ~~(job_details[0].lengthInMonths / 12)
              : this.state.LengthYears,
            patientEmploymentHistoryID: job_details[0]
              .patientEmploymentHistoryID
              ? job_details[0].patientEmploymentHistoryID
              : this.state.patientEmploymentHistoryID,
            LastEmpDate:
              job_details[0].lastDayDate &&
              this.dateFormatter(job_details[0].lastDayDate) !== "1900-01-01"
                ? this.dateFormatter(job_details[0].lastDayDate)
                : this.dateFormatter(new Date(this.currentYear, 0, 1)),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    getPESDetails({
      value: getKNC(this.cookie.access_token),
      PPPESID: this.state.workflow["parentEntityID"],
    })
      .then(({ data }) => {
        var pes_details = data[0];
        /*
        220602 VG: value from API is tru where value in UI is "true"
        */
        this.setState({
          apply_for:
            pes_details.applyFor === false &&
            this.state.workflow["dateCompleted"] === null
              ? ""
              : pes_details.applyFor === true
              ? "true"
              : "false",
          diarrhoea: pes_details.diarrhoea
            ? pes_details.diarrhoea
            : this.state.diarrhoea,
          skin_disorder: pes_details.skinDisorder
            ? pes_details.skinDisorder
            : this.state.skin_disorder,
          skin_disorder_details: pes_details.skinDisorderDetails
            ? pes_details.skinDisorderDetails
            : this.state.skin_disorder_details,
          weeks_four: pes_details.weeksFour
            ? pes_details.weeksFour
            : this.state.weeks_four,
          weeks_four_details: pes_details.weeksFourDetails
            ? pes_details.weeksFourDetails
            : this.state.weeks_four_details,
          weeks_thirteen: pes_details.weeksThirteen
            ? pes_details.weeksThirteen
            : this.state.weeks_thirteen,
          weeks_thirteen_details: pes_details.weeksThirteenDetails
            ? pes_details.weeksThirteenDetails
            : this.state.weeks_thirteen_details,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.state.workflow["dateCompleted"] === null) {
      this.getNextworkflow();
    }
  }
  getNextworkflow = async () => {
    getNextWorkFlowDetails({
      workflowValues:
        this.state.workflow["targetEntityID"] +
        "," +
        this.state.workflow["targetEntityType"] +
        "," +
        this.state.workflow["wfeProcessID"],
    })
      .then(({ data }) => {
        console.log(data);
        this.setState({
          workflow: data["table"][0],
          nextWorkflow:
            data["table1"] !== [] ? data["table1"][0] : data["table1"],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  RadiobtnStyle = (num, sptxt, type, tmpstate, onClickAction) => {
    return (
      <div
        className="custom-radio rounded"
        onClick={() => this.handleChange(type)}
      >
        <input
          type="radio"
          className="custom-input"
          value={num}
          name={type}
          checked={tmpstate === num.toString()}
          onChange={this.handleChange(type)}
          onClick={() => onClickAction && onClickAction(type)}
        />
        <span>{sptxt}</span>
      </div>
    );
  };
  changeLoadingCircle = (flag) => {
    this.setState({ loadingCircle: flag });
    return true;
  };
  handleChange = (input) => (e) => {
    var name = /^[a-zA-Z '-]*$/;

    if (e.target) {
      this.setState({
        [input]: e.target.value,
      });
    } else {
      this.setState({
        [input]: e.value,
      });
    }
    if (e.target.id === "LastEmpDate") {
      var today = new Date();
      if (today < new Date(e.target.value)) {
        this.setState({
          LastEmpDateisValid: false,
          LastEmpDateError: "Date cannot be greater than today",
        });
      } else
        this.setState({
          LastEmpDateisValid: true,
          LastEmpDateError: "",
        });
    }
    if (e.target.id === "LengthYears") {
      var years = /^\d{1,2}$/;
      if (e.target.value.length > 0 && !e.target.value.match(years)) {
        this.setState({
          LengthYearsError: "Please enter valid years",
          LengthYearsisValid: false,
        });
        return false;
      } else this.setState({ LengthYearsisValid: true, LengthYearsError: "" });
      return true;
    }
    if (e.target.id === "LengthMonths") {
      if (e.target.value.length > 0 && !(parseInt(e.target.value) <= 12)) {
        this.setState({
          LengthMonthsError: "Please enter valid month",
          LengthMonthsisValid: false,
        });
        return false;
      } else
        this.setState({ LengthMonthsisValid: true, LengthMonthsError: "" });
      return true;
    }
    if (e.target.id === "PreviousJob") {
      if (!e.target.value.match(name)) {
        this.setState({
          previousJobError: "No numbers or special characters allowed",
          previousJobisValid: false,
        });
        return false;
      }
      if (e.target.value.length > 250) {
        this.setState({
          previousJobError:
            "Previous job is too long. Maximum length is 250 characters",
          previousJobisValid: false,
        });
        return false;
      } else this.setState({ previousJobisValid: true, previousJobError: "" });
      return true;
    }
    if (e.target.id === "Employer") {
      if (!e.target.value.match(name)) {
        this.setState({
          employerError: "No numbers or special characters allowed",
          employerisValid: false,
        });
        return false;
      }
      if (e.target.value.length > 250) {
        this.setState({
          employerError:
            "Employer name is too long. Maximum length is 250 characters",
          employerisValid: false,
        });
        return false;
      } else this.setState({ employerisValid: true, employerError: "" });
      return true;
    }
  };
  continue = async (e) => {
    const isValid = this.validate();
    if (
      isValid &&
      this.state.LengthYearsisValid &&
      this.state.LengthMonthsisValid &&
      this.state.LastEmpDateisValid &&
      this.state.previousJobisValid &&
      this.state.employerisValid
    ) {
      this.changeLoadingCircle(true);
      const schema = {
        job_schema: {
          PreviousJob: this.state.PreviousJob,
          Employer: this.state.Employer,
          LengthMonths:
            Number(this.state.LengthMonths) +
            Number(this.state.LengthYears * 12),
          LastEmpDate: this.state.LastEmpDate
            ? this.state.LastEmpDate
            : new Date(DEFAULT_DATE),
          UUID: this.state.KNC
            ? this.state.KNC
            : getKNC(this.cookie.access_token),
          PatientEmploymentHistoryID: this.state.patientEmploymentHistoryID
            ? this.state.patientEmploymentHistoryID
            : -1,
          WorkflowID:
            this.state.workflow["dateCompleted"] === null
              ? this.state.workflow["wfeWorkflowID"]
              : -1,
        },
        pes_medical_history: {
          UUID: this.state.KNC
            ? this.state.KNC
            : getKNC(this.cookie.access_token),
          ParentEntityID: this.state.workflow["parentEntityID"],
          ParentEntityType: this.state.workflow["parentEntityType"],
          MedicalConditionsEffectingTest:
            this.state.medical_conditions_effecting_test === "No"
              ? "No"
              : this.state.medical_conditions_effecting_test +
                "|" +
                this.state.medical_conditions_effecting_test_reason,
        },
        pes_food_questionnaire: {
          UUID: this.state.KNC
            ? this.state.KNC
            : getKNC(this.cookie.access_token),
          ApplyFor: this.state.apply_for,
          Diarrhoea: this.state.diarrhoea === "" ? false : this.state.diarrhoea,
          SkinDisorder:
            this.state.skin_disorder === "" ? false : this.state.skin_disorder,
          SkinDisorderDetails: this.state.skin_disorder_details,
          WeeksFour:
            this.state.weeks_four === "" ? false : this.state.weeks_four,
          WeeksFourDetails: this.state.weeks_four_details,
          WeeksThirteen:
            this.state.weeks_thirteen === ""
              ? false
              : this.state.weeks_thirteen,
          WeeksThirteenDetails: this.state.weeks_thirteen_details,
          WorkflowID:
            this.state.apply_for === "false"
              ? this.state.workflow["nextWorkflowID"]
              : -1,
          PPPESID: this.state.workflow["parentEntityID"],
        },
      };
      // implementing save after each page
      await saveJobDetails(schema.job_schema)
        .then(() => {
          e.preventDefault();
        })
        .catch((error) => {
          window.alert(error);
        });
      await getNextWorkFlowDetails({
        workflowValues:
          this.state.workflow["targetEntityID"] +
          "," +
          this.state.workflow["targetEntityType"] +
          "," +
          this.state.workflow["wfeProcessID"],
      })
        .then(({ data }) => {
          console.log(data);
          this.setState({
            workflow_review: data["table"][0],
            nextWorkflow_review: data["table1"][0],
          });
        })
        .catch((error) => {
          console.log(error);
        });
      await savePESMedHistoryDetails(schema.pes_medical_history)
        .then(() => {
          e.preventDefault();
        })
        .catch((error) => {
          window.alert(error);
        });
      await savePESFoodQuestionnaireDetails(schema.pes_food_questionnaire)
        .then(() => {
          e.preventDefault();
        })
        .catch((error) => {
          window.alert(error);
        });
      if (this.state.workflow["dateCompleted"] !== null) {
        showPage(this.state.workflow, this.props);
      } else if (
        this.state.apply_for === "true" &&
        this.state.workflow["dateCompleted"] === null
      ) {
        showPage(this.state.nextWorkflow, this.props);
      } else if (
        this.state.apply_for === "false" &&
        this.state.workflow["dateCompleted"] === null
      ) {
        showPage(this.state.nextWorkflow_review, this.props);
      }
    } else this.setState({ submit: true });
    this.changeLoadingCircle(false);
  };
  validate = () => {
    let nameError = "";

    const val = this.state;
    if (
      val.medical_conditions_effecting_test === "" ||
      (val.medical_conditions_effecting_test === "Yes" &&
        val.medical_conditions_effecting_test_reason === "") ||
      val.apply_for === ""
    ) {
      nameError = "This field is required";
    }
    if (nameError) {
      this.setState({ nameError });
      return false;
    } else {
      this.setState({
        nameError: "",
      });
      return true;
    }
  };
  display = () => {
    const today = new Date();
    const { loadingCircle } = this.props.pageProps;
    if (
      this.state.workflow &&
      this.state.workflow !== [] &&
      this.state.workflow["wfeModuleID"] === WFE_MODULE.JOB_HISTORY_DETAILS
    ) {
      return (
        <div id="MainDiv">
          {this.state.loadingCircle === true ? loadingCircle : null}
          <div className="has-form">
            <div className="title">
              {this.state.workflow["dateCompleted"] === null && (
                <div className="div-progress-bar">
                  <p>{this.state.workflow.statusDisplay}</p>
                  <ProgressBar
                    animated
                    now={this.state.workflow.statusPercentage}
                  />
                </div>
              )}
              <h1>Job History Details</h1>
            </div>
          </div>
          <div className="has-form">
            <div className="row">
              <div className="form-group">
                <div className="custom-radio-wrapper">
                  <label className="abc">
                    You will be taking part in a functional assessment that will
                    be physically demanding. Do you have any medical conditions
                    that may affect your ability to perform the tests safely?
                  </label>
                  <div className="errorMessage">
                    {this.state.medical_conditions_effecting_test === "" &&
                      this.state.nameError}
                  </div>
                  <RadioGroup>
                    {this.RadiobtnStyle(
                      "Yes",
                      "Yes",
                      "medical_conditions_effecting_test",
                      this.state.medical_conditions_effecting_test
                    )}
                    {this.RadiobtnStyle(
                      "No",
                      "No",
                      "medical_conditions_effecting_test",
                      this.state.medical_conditions_effecting_test
                    )}
                  </RadioGroup>
                </div>

                {this.state.medical_conditions_effecting_test === "Yes" && (
                  <div className="col-md-12 has-form">
                    <label className="abc">Tell us more</label>
                    <div className="errorMessage">
                      {this.state.medical_conditions_effecting_test === "Yes" &&
                        this.state.medical_conditions_effecting_test_reason ===
                          "" &&
                        this.state.nameError}
                    </div>
                    <textarea
                      className="form-control"
                      rows="1"
                      cols="5"
                      onChange={this.handleChange(
                        "medical_conditions_effecting_test_reason"
                      )}
                      value={
                        this.state.medical_conditions_effecting_test_reason
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label className="abc">
                  Previous Job<span className="optional">Optional</span>
                </label>
                <div className="errorMessage">
                  {this.state.previousJobError}
                </div>
                <input
                  className="form-control"
                  id="PreviousJob"
                  name="PreviousJob "
                  type="text"
                  value={this.state.PreviousJob}
                  onChange={this.handleChange("PreviousJob")}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label className="abc">
                  Employer<span className="optional">Optional</span>
                </label>
                <div className="errorMessage">{this.state.employerError}</div>
                <input
                  className="form-control"
                  id="Employer"
                  name="Employer"
                  type="text"
                  value={this.state.Employer}
                  onChange={this.handleChange("Employer")}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label className="abc">Length of Service</label>
                <label className="abc">
                  Years<span className="optional">Optional</span>
                </label>

                <input
                  className="form-control"
                  id="LengthYears"
                  name="LengthYears"
                  type="number"
                  value={this.state.LengthYears}
                  onChange={this.handleChange("LengthYears")}
                />
                <div className="errorMessage">
                  {this.state.LengthYearsError}
                </div>
                <label className="abc">
                  Months<span className="optional">Optional</span>
                </label>

                <input
                  className="form-control"
                  id="LengthMonths"
                  name="LengthMonths"
                  type="number"
                  value={this.state.LengthMonths}
                  onChange={this.handleChange("LengthMonths")}
                />
                <div className="errorMessage">
                  {this.state.LengthMonthsError}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label className="abc">
                  When was the last time you held a job?
                  <span className="optional">Optional</span>
                </label>
                <div className="errorMessage">
                  {this.state.LastEmpDateError}
                </div>
                <input
                  className="form-control"
                  id="LastEmpDate"
                  name="LastEmpDate"
                  type="date"
                  max={today.toISOString().split("T")[0]}
                  value={this.state.LastEmpDate}
                  onChange={this.handleChange("LastEmpDate")}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <div className="custom-radio-wrapper">
                  <label className="abc">
                    Are you a candidate for a role within the food manufacturing
                    or processing industry?
                  </label>
                  <div className="errorMessage">
                    {this.state.apply_for === "" && this.state.nameError}
                  </div>
                  <RadioGroup>
                    {this.RadiobtnStyle(
                      "true",
                      "Yes",
                      "apply_for",
                      this.state.apply_for
                    )}
                    {this.RadiobtnStyle(
                      "false",
                      "No",
                      "apply_for",
                      this.state.apply_for
                    )}
                  </RadioGroup>
                </div>
              </div>
            </div>
            <div className="btn-block prev-back-btn">
              {/* <button className="btn btn-outline-primary" onClick={this.back}>
              Back
            </button> */}
              <button
                className="btn btn-primary modal-btn"
                data-modal-id="sampleModal"
                onClick={this.continue}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      auth.login(() => {
        this.props.history.push({
          pathname: "/",
        });
      });
    }
  };
  render() {
    return <div>{this.display()}</div>;
  }
}
export default PESJobDetails;
/*
220302 VG: v2 code
<p> Step 2 of 3 </p>          
<div>
              <div className="form-group">
                <label className="abc">Line/Task</label>
                <input
                  className="form-control"
                  id="LineTask"
                  name="LineTask"
                  type="text"
                  value={state.LineTask}
                  onChange={handleChange("LineTask")}
                />
              </div>
            </div> 

             <div className="form-group custom-radio-wrapper">
              <label className="abc">
                Have you ever made a claim for workers compensation in any
                employment within Australia?
              </label>

              <div className="custom-radio rounded">
                <input
                  type="radio"
                  className="custom-input"
                  value="1"
                  id="CompClaim"
                  checked={state.CompClaim === "1"}
                  onChange={handleChange("CompClaim")}
                />
                <span>Yes</span>
              </div>
              <div className="custom-radio rounded">
                <input
                  type="radio"
                  className="custom-input"
                  value="0"
                  id="CompClaim"
                  checked={state.CompClaim === "0"}
                  onChange={handleChange("CompClaim")}
                />
                <span>No</span>
              </div>
            </div> 

             {RenderCompClaim()} 

             {this.state.submit ? <Errormsg /> : null} 
             <div>
              <div className="form-group">
                <label className="abc">Employment Start Date</label>
                <input
                  className="form-control"
                  id="EmpStDate"
                  name="EmpStDate"
                  type="date"
                  max={format(new Date(), "yyyy-MM-dd")}
                  value={state.EmpStDate}
                  onChange={handleChange("EmpStDate")}
                />
                <div className="errorMessage">{state.EmpStDateError}</div>
              </div>
            </div> 

            <div>
              <div className="form-group">
                <label className="abc">Department</label>
                <input
                  className="form-control"
                  id="Department"
                  name="Department"
                  type="text"
                  value={state.Department}
                  onChange={handleChange("Department")}
                />
              </div>
            </div>
            <div>
              <div className="form-group">
                <label className="abc">Current Position</label>
                <input
                  className="form-control"
                  id="CurrentPosition"
                  name="CurrentPosition"
                  type="text"
                  value={state.CurrentPosition}
                  onChange={handleChange("CurrentPosition")}
                />
              </div>
            </div>
              continue = (e) => {
    if (
      //!( this.props.state.CompClaim === "") &&
      this.props.state.LastEmpDateisValid
      // && this.props.state.CompClaimDetailsisValid
    ) {
      e.preventDefault();
      this.props.nextStep();
    } else if (
      //  this.props.state.CompClaim === "0" &&
      this.props.state.LastEmpDateisValid
    ) {
      // this.setState({ CompClaimDetailsisValid: true });
      this.props.nextStep();
    } else this.setState({ submit: true });
  };
      const RenderCompClaim = () => {
      if (state.CompClaim === "1") {
        return (
          <React.Fragment>
            <div>
              <div className="form-group">
                <label className="abc">
                  Please explain how long you were off work, on light duties,
                  the type of injury, and any other relevant information
                </label>
                <textarea
                  className="form-control"
                  id="CompClaimDetails"
                  name="CompClaimDetails"
                  type="textarea"
                  value={state.CompClaimDetails}
                  onChange={handleChange("CompClaimDetails")}
                ></textarea>
              </div>
            </div>
            <div className="errorMessage">{state.CompClaimDetailsError}</div>
          </React.Fragment>
        );
      } else {
        return null;
      }
    };
           const Errormsg = () => (
  <div className="errorMessage">Missing or invalid fields</div>
);  */
