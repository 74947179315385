import React, { Component } from "react";
import Select from "react-select";
import {
  savePersonalDetails,
  getPersonalDetails,
  getNextWorkFlowDetails,
} from "../../services/api";
import auth from "../auth";
import { withRouter } from "react-router-dom";
import { getCookie, getKNC } from "../../services/cookieService";
import { customStyles } from "../../services/constants";
import ProgressBar from "react-bootstrap/ProgressBar";

class PersonalDetails extends Component {
  cookie = getCookie();
  state = {
    submit: false,
    ethnicityoptions: this.props.state.ethnicityoptions,
    dateerr: "",
    loadingCircle: false,
  };
  continue = (e) => {
    const isValid = this.validate();

    if (
      isValid &&
      this.props.state.givenNameisValid &&
      this.props.state.surNameisValid &&
      this.props.state.middleNameisValid &&
      this.props.state.DateofBisValid &&
      this.props.state.heightisValid &&
      this.props.state.weightisValid
    ) {
      this.changeLoadingCircle(true);
      const schema = {
        schema: {
          Title: this.props.state.titleOpt,
          FirstName: this.props.state.givenName,
          LastName: this.props.state.surName,
          MiddleNames: this.props.state.middleName,
          DateOfBirth: this.props.state.DateofB,

          culturalGroup: this.props.state.ethnicityCode
            ? this.props.state.ethnicityCode
            : 1101,
          Height: this.props.state.height,
          WeightKg: this.props.state.weight,
          Handedness: this.props.state.handedness,

          UUID: this.props.state.KNC
            ? this.props.state.KNC
            : getKNC(this.cookie.access_token),
        },
      };
      //implementing save after each page
      savePersonalDetails(schema.schema)
        .then(() => {
          e.preventDefault();
          this.props.nextStep();
          this.changeLoadingCircle(false);
        })
        .catch((error) => {
          window.alert(error);
          this.changeLoadingCircle(false);
        });
    } else {
      this.setState({ submit: true });
    }
  };

  validate = () => {
    let nameError = "";

    const val = this.props.state;

    if (
      val.titleOpt === "" ||
      val.givenName === "" ||
      val.surName === "" ||
      !val.DateofB ||
      !val.culturalGroup ||
      val.DateofB === "" ||
      new Date().getFullYear() - new Date(val.DateofB).getFullYear() < 15 ||
      val.handedness === "" ||
      val.height === "" ||
      val.weight === ""
    ) {
      this.setState({ dateerr: "Invalid date" });
      nameError = "This field is required";
    }
    if (nameError) {
      this.setState({ nameError });
      return false;
    } else {
      this.setState({
        nameError: "",
        dateerr: "",
      });
      return true;
    }
  };
  changeLoadingCircle = (flag) => {
    this.setState({ loadingCircle: flag });
    return true;
  };
  saveEthnicity = (data) => {
    try {
      this.setState({ ethnicityoptions: this.props.state.ethnicityoptions });
      this.props.ethnicityCodef(data);
    } catch (err) {
      console.log(err);
    }
  };
  goBack = () => {
    auth.login(() => {
      this.props.history.push("/");
    });
  };

  render() {
    const { handleChange, state, Leftarrow, loadingCircle } = this.props;

    return (
      <div id="MainDiv">
        {this.state.loadingCircle === true ? loadingCircle : null}
        <div className="has-form">
          <div className="title">
            {Leftarrow("/")}
            {this.props.state.workflow["dateCompleted"] === null && (
              <div className="div-progress-bar">
                <p>{this.props.state.workflow.statusDisplay}</p>
                <ProgressBar
                  animated
                  now={this.props.state.workflow.statusPercentage}
                />
              </div>
            )}
            <h1>Personal Details</h1>
          </div>
        </div>
        <div className="has-form">
          <div className="row">
            <div className="form-group">
              <div className="custom-radio-wrapper">
                <label className="abc">Title</label>
                <div
                  className="custom-radio rounded"
                  onClick={() => handleChange("titleOpt")}
                >
                  <input
                    type="radio"
                    className="custom-input"
                    name="radio1"
                    value="Mr"
                    id="titleOpt"
                    checked={state.titleOpt === "Mr"}
                    onChange={handleChange("titleOpt")}
                  />
                  <span>Mr</span>
                </div>
                <div
                  className="custom-radio rounded"
                  onClick={() => handleChange("titleOpt")}
                >
                  <input
                    type="radio"
                    className="custom-input"
                    name="radio1"
                    value="Ms"
                    id="titleOpt"
                    checked={state.titleOpt === "Ms"}
                    onChange={handleChange("titleOpt")}
                  />
                  <span>Ms</span>
                </div>
                {/* <div className="custom-radio rounded">
                <input
                  type="radio"
                  className="custom-input"
                  name="radio1"
                  value="Mx"
                  id="titleOpt"
                  checked={state.titleOpt === "Mx"}
                  onChange={handleChange("titleOpt")}
                />
                <span>Mx</span>
              </div> */}

                <div className="errorMessage">{state.titleOptError}</div>
                <div className="errorMessage">
                  {this.props.state.titleOpt === "" && this.state.nameError}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label className="abc">Given Name</label>
              <input
                className="form-control"
                id="givenName"
                type="text"
                value={state.givenName}
                onChange={handleChange("givenName")}
              />
              <div className="errorMessage">{state.givenNameError}</div>
              <div className="errorMessage">
                {state.givenNameError
                  ? null
                  : this.props.state.givenName === "" && this.state.nameError}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label className="abc">Surname</label>
              <input
                className="form-control"
                id="surName"
                type="text"
                value={state.surName}
                onChange={handleChange("surName")}
              />
              <div className="errorMessage">{state.surNameError}</div>
              <div className="errorMessage">
                {state.surNameError
                  ? null
                  : this.props.state.surName === "" && this.state.nameError}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label className="abc">
                Middle Name(s) <span className="optional">Optional</span>{" "}
              </label>
              <input
                className="form-control"
                id="middleName"
                type="text"
                value={state.middleName}
                onChange={handleChange("middleName")}
              />
              <div className="errorMessage">{state.middleNameError}</div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label className="abc">Date of Birth</label>
              <input
                className="form-control"
                id="DateofB"
                name="DateofB"
                type="date"
                value={state.DateofB}
                onChange={handleChange("DateofB")}
              />
              <div className="errorMessage">{state.DateofBError}</div>
              <div className="errorMessage">
                {state.DateofBError
                  ? null
                  : !this.props.state.DateofB && this.state.dateerr}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label className="abc">Nationality</label>
              <Select
                options={this.state.ethnicityoptions}
                // theme={(theme) => ({
                //   ...theme,
                //     borderRadius: 8,
                // })}
                styles={customStyles}
                isSearchable
                menuPlacement="top"
                onChange={handleChange("culturalGroup")}
                value={state.culturalGroup}
              ></Select>
              <div className="errorMessage">
                {!this.props.state.culturalGroup && this.state.nameError}
              </div>
              <br></br>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label className="abc">Height (cm)</label>
              <input
                type="number"
                id="height"
                className="form-control"
                value={state.height}
                placeholder="give approximate if unsure"
                onChange={handleChange("height")}
              ></input>
              <div className="errorMessage">
                {state.heightError ? (
                  <div className="errorMessage">{state.heightError}</div>
                ) : (
                  this.props.state.height === "" && this.state.nameError
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label className="abc">Weight (kg)</label>
              <input
                type="number"
                id="weight"
                className="form-control"
                placeholder="give approximate if unsure"
                value={state.weight}
                onChange={handleChange("weight")}
              ></input>

              <div className="errorMessage">
                {state.weightError ? (
                  <div className="errorMessage">{state.weightError}</div>
                ) : (
                  this.props.state.weight === "" && this.state.nameError
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <div className="custom-radio-wrapper">
                <label className="abc">Which is your dominant hand?</label>
                <div
                  className="custom-radio rounded"
                  onClick={() => handleChange("handedness")}
                >
                  <input
                    className="custom-input"
                    id="handedness"
                    type="radio"
                    value="Left"
                    checked={state.handedness === "Left"}
                    onChange={handleChange("handedness")}
                  />
                  <span>Left</span>
                </div>

                <div
                  className="custom-radio rounded"
                  onClick={() => handleChange("handedness")}
                >
                  <input
                    className="custom-input"
                    id="handedness"
                    type="radio"
                    value="Right"
                    checked={state.handedness === "Right"}
                    onChange={handleChange("handedness")}
                  />
                  <span>Right</span>
                </div>

                <div
                  className="custom-radio rounded"
                  onClick={() => handleChange("handedness")}
                >
                  <input
                    className="custom-input"
                    id="handedness"
                    type="radio"
                    value="Ambidextrous"
                    checked={state.handedness === "Ambidextrous"}
                    onChange={handleChange("handedness")}
                  />
                  <span>Ambidextrous</span>
                </div>
              </div>

              <div className="errorMessage">{state.handednessError}</div>
              <div className="errorMessage">
                {state.handednessError
                  ? null
                  : this.props.state.handedness === "" && this.state.nameError}
              </div>
            </div>
          </div>

          <div className="btn-block prev-back-btn">
            {/* <button className="btn btn-outline-primary" onClick={this.goBack}>
                Back
              </button> */}
            <button
              className="btn btn-primary modal-btn"
              data-modal-id="sampleModal"
              onClick={this.continue}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(PersonalDetails);

/* 220301 VG: part of v2
       this.props.state.emailError !== "Invalid email" &&
       this.props.state.mobileNumberisValid &&
       this.props.state.email !== ""
       Gender: this.props.state.gender,
       Mobile: this.props.state.mobileNumber,
       val.gender === "" ||
       val.mobileNumber === ""
        <p> Step 1 of 3 </p> 
        <div className="custom-radio rounded">
                <input
                  type="radio"
                  className="custom-input"
                  name="radio1"
                  value="Ms"
                  id="titleOpt"
                  checked={state.titleOpt === "Ms"}
                  onChange={handleChange("titleOpt")}
                />
                <span>Ms</span>
              </div>
              <div className="custom-radio rounded">
                <input
                  type="radio"
                  className="custom-input"
                  name="radio1"
                  value="Other"
                  id="titleOpt"
                  checked={state.titleOpt === "Other"}
                  onChange={handleChange("titleOpt")}
                />
                <span>Other</span>
              </div> 
              <div className="form-group custom-radio-wrapper">
              <label className="abc">Gender</label>

              <div className="custom-radio rounded">
                <input
                  type="radio"
                  className="custom-input"
                  name="radio2"
                  value="M"
                  id="gender"
                  checked={state.gender === "M"}
                  onChange={handleChange("gender")}
                />
                <span>Male</span>
              </div>
              <div className="custom-radio rounded">
                <input
                  type="radio"
                  className="custom-input"
                  name="radio2"
                  value="F"
                  id="gender"
                  checked={state.gender === "F"}
                  onChange={handleChange("gender")}
                />
                <span>Female</span>
              </div>

              <div className="custom-radio rounded">
                <input
                  type="radio"
                  className="custom-input"
                  name="radio2"
                  value="U"
                  id="gender"
                  checked={state.gender === "U"}
                  onChange={handleChange("gender")}
                />
                <span>Other</span>
              </div>
              <div className="errorMessage">{state.genderError}</div>
              <div className="errorMessage">
                {state.genderError
                  ? null
                  : this.props.state.gender === "" && this.state.nameError}
              </div>
            </div>
            <div>
              <div className="form-group">
                <label className="abc">Phone Number</label>
                <input
                  className="form-control"
                  id="mobileNumber"
                  type="text"
                  value={state.mobileNumber}
                  onChange={handleChange("mobileNumber")}
                />

                <div className="errorMessage">{state.mobileNumberError}</div>
                <div className="errorMessage">
                  {state.mobileNumberError
                    ? null
                    : this.props.state.mobileNumber === "" &&
                      this.state.nameError}
                </div>
              </div>
            </div> 
             <div>
              <div className="form-group">
                <label className="abc">Email</label>
                <input
                  className="form-control"
                  id="email"
                  type="email"
                  disabled="true"
                  value={state.email}
                  onChange={handleChange("email")}
                />
                <div className="errorMessage">{state.emailError}</div>
                <div className="errorMessage">
                  {state.emailError
                    ? null
                    : this.props.state.email === "" && this.state.nameError}
                </div>
              </div>
            </div>
       */
