import React, { Component } from "react";
//import { format } from "date-fns";
import { DEFAULT_DATE } from "../../services/constants";
import { getCookie, getKNC } from "../../services/cookieService";
import { saveJobDetails } from "../../services/api";
import { RadioGroup } from "react-radio-group";
class JobDetails extends Component {
  cookie = getCookie();
  state = { submit: false };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  continue = (e) => {
    if (
      this.props.state.LengthYearsisValid &&
      this.props.state.LengthMonthsisValid &&
      this.props.state.LastEmpDateisValid
    ) {
      const schema = {
        schema: {
          PreviousJob: this.props.state.PreviousJob,
          Employer: this.props.state.Employer,
          LengthMonths:
            Number(this.props.state.LengthMonths) +
            Number(this.props.state.LengthYears * 12),
          LastEmpDate: this.props.state.LastEmpDate
            ? this.props.state.LastEmpDate
            : new Date(DEFAULT_DATE),
          UUID: this.props.state.KNC
            ? this.props.state.KNC
            : getKNC(this.cookie.access_token),
          PatientEmploymentHistoryID: this.props.state
            .patientEmploymentHistoryID
            ? this.props.state.patientEmploymentHistoryID
            : -1,
        },
      };
      // implementing save after each page
      saveJobDetails(schema.schema)
        .then(() => {
          e.preventDefault();
          //     this.props.nextStep();
        })
        .catch((error) => {
          window.alert(error);
        });
    } else this.setState({ submit: true });
  };
  render() {
    const { handleChange, state, RadiobtnStyle } = this.props;
    const today = new Date();

    return (
      <div id="MainDiv">
        <div className="page-title lg">
          <div className="title">
            <h1>Current or Previous Job Details</h1>
          </div>
        </div>
        <div className="contentSpacing">
          <div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group custom-radio-wrapper">
                  <label className="abc">
                    You will be taking part in a functional assessment that will
                    be physically demanding. Do you have any medical conditions
                    that may affect your ability to perform the tests safely?
                  </label>
                  <label className="errorMessage">
                    {state.allergies === "" && this.state.nameError}
                  </label>
                  <RadioGroup>
                    {RadiobtnStyle("Yes", "Yes", "allergies", state.allergies)}
                    {RadiobtnStyle("No", "No", "allergies", state.allergies)}
                  </RadioGroup>
                </div>
              </div>
            </div>

            {state.allergies === "Yes" && (
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label className="abc">Tel us more</label>
                    <label className="errorMessage">
                      {state.allergies === "Yes" &&
                        state.allergies_reason === "" &&
                        this.state.nameError}
                    </label>
                    <textarea
                      className="form-control"
                      rows="1"
                      cols="5"
                      onChange={handleChange("allergies_reason")}
                      value={state.allergies_reason}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row has-form">
            <div>
              <div className="form-group">
                <label className="abc">
                  Previous Job<span className="optional">Optional</span>
                </label>
                <input
                  className="form-control"
                  id="PreviousJob"
                  name="PreviousJob "
                  type="text"
                  value={state.PreviousJob}
                  onChange={handleChange("PreviousJob")}
                />
              </div>
            </div>
            <div>
              <div className="form-group">
                <label className="abc">
                  Employer<span className="optional">Optional</span>
                </label>
                <input
                  className="form-control"
                  id="Employer"
                  name="Employer"
                  type="text"
                  value={state.Employer}
                  onChange={handleChange("Employer")}
                />
              </div>
            </div>
            <div>
              <div className="form-group">
                <label className="abc">Length of Service</label>
                <label className="abc">
                  Years<span className="optional">Optional</span>
                </label>
                <input
                  className="form-control"
                  id="LengthYears"
                  name="LengthYears"
                  type="text"
                  value={state.LengthYears}
                  onChange={handleChange("LengthYears")}
                />
                <div className="errorMessage">{state.LengthYearsError}</div>
                <label className="abc">
                  Months<span className="optional">Optional</span>
                </label>
                <input
                  className="form-control"
                  id="LengthMonths"
                  name="LengthMonths"
                  type="text"
                  value={state.LengthMonths}
                  onChange={handleChange("LengthMonths")}
                />
                <div className="errorMessage">{state.LengthMonthsError}</div>
              </div>
            </div>
            <div>
              <div className="form-group">
                <label className="abc">
                  Last Employment Date<span className="optional">Optional</span>
                </label>
                <input
                  className="form-control"
                  id="LastEmpDate"
                  name="LastEmpDate"
                  type="date"
                  max={today.toISOString().split("T")[0]}
                  value={state.LastEmpDate}
                  onChange={handleChange("LastEmpDate")}
                />
                <div className="errorMessage">{state.LastEmpDateError}</div>
              </div>
            </div>
            <div className="btn-block prev-back-btn">
              <button className="btn btn-outline-primary" onClick={this.back}>
                Back
              </button>
              <button
                className="btn btn-primary modal-btn"
                data-modal-id="sampleModal"
                onClick={this.continue}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default JobDetails;
/*
220302 VG: v2 code
<p> Step 2 of 3 </p>          
<div>
              <div className="form-group">
                <label className="abc">Line/Task</label>
                <input
                  className="form-control"
                  id="LineTask"
                  name="LineTask"
                  type="text"
                  value={state.LineTask}
                  onChange={handleChange("LineTask")}
                />
              </div>
            </div> 

             <div className="form-group custom-radio-wrapper">
              <label className="abc">
                Have you ever made a claim for workers compensation in any
                employment within Australia?
              </label>

              <div className="custom-radio rounded">
                <input
                  type="radio"
                  className="custom-input"
                  value="1"
                  id="CompClaim"
                  checked={state.CompClaim === "1"}
                  onChange={handleChange("CompClaim")}
                />
                <span>Yes</span>
              </div>
              <div className="custom-radio rounded">
                <input
                  type="radio"
                  className="custom-input"
                  value="0"
                  id="CompClaim"
                  checked={state.CompClaim === "0"}
                  onChange={handleChange("CompClaim")}
                />
                <span>No</span>
              </div>
            </div> 

             {RenderCompClaim()} 

             {this.state.submit ? <Errormsg /> : null} 
             <div>
              <div className="form-group">
                <label className="abc">Employment Start Date</label>
                <input
                  className="form-control"
                  id="EmpStDate"
                  name="EmpStDate"
                  type="date"
                  max={format(new Date(), "yyyy-MM-dd")}
                  value={state.EmpStDate}
                  onChange={handleChange("EmpStDate")}
                />
                <div className="errorMessage">{state.EmpStDateError}</div>
              </div>
            </div> 

            <div>
              <div className="form-group">
                <label className="abc">Department</label>
                <input
                  className="form-control"
                  id="Department"
                  name="Department"
                  type="text"
                  value={state.Department}
                  onChange={handleChange("Department")}
                />
              </div>
            </div>
            <div>
              <div className="form-group">
                <label className="abc">Current Position</label>
                <input
                  className="form-control"
                  id="CurrentPosition"
                  name="CurrentPosition"
                  type="text"
                  value={state.CurrentPosition}
                  onChange={handleChange("CurrentPosition")}
                />
              </div>
            </div>
              continue = (e) => {
    if (
      //!( this.props.state.CompClaim === "") &&
      this.props.state.LastEmpDateisValid
      // && this.props.state.CompClaimDetailsisValid
    ) {
      e.preventDefault();
      this.props.nextStep();
    } else if (
      //  this.props.state.CompClaim === "0" &&
      this.props.state.LastEmpDateisValid
    ) {
      // this.setState({ CompClaimDetailsisValid: true });
      this.props.nextStep();
    } else this.setState({ submit: true });
  };
      const RenderCompClaim = () => {
      if (state.CompClaim === "1") {
        return (
          <React.Fragment>
            <div>
              <div className="form-group">
                <label className="abc">
                  Please explain how long you were off work, on light duties,
                  the type of injury, and any other relevant information
                </label>
                <textarea
                  className="form-control"
                  id="CompClaimDetails"
                  name="CompClaimDetails"
                  type="textarea"
                  value={state.CompClaimDetails}
                  onChange={handleChange("CompClaimDetails")}
                ></textarea>
              </div>
            </div>
            <div className="errorMessage">{state.CompClaimDetailsError}</div>
          </React.Fragment>
        );
      } else {
        return null;
      }
    };
           const Errormsg = () => (
  <div className="errorMessage">Missing or invalid fields</div>
);  */
