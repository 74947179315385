import React, { Component } from "react";
import "../../App.css";
import { RadioGroup } from "react-radio-group";
import "react-dropdown/style.css";
import auth from "../auth";
import {
  getPESDetails,
  savePESFoodQuestionnaireDetails,
  createSaveWorkflow,
  getNextWorkFlowDetails,
  showPage,
} from "../../services/api";
import { getCookie, getKNC } from "../../services/cookieService";
import { WFE_MODULE } from "../../services/constants";
import ProgressBar from "react-bootstrap/ProgressBar";
class FoodHandlersQuestionnaire extends Component {
  cookie = getCookie();
  constructor(props) {
    super(props);
    this.initialState = {
      apply_for: "",
      diarrhoea: "",
      skin_disorder: "",
      skin_disorder_details: "",
      weeks_four: "",
      weeks_four_details: "",
      weeks_thirteen: "",
      weeks_thirteen_details: "",
      nameError: "",
      workflow: this.props?.history?.location?.state?.workflow
        ? this.props.history.location.state.workflow
        : [],
      nextWorkflow: [],
      loadingCircle: false,
    };
    this.state = this.initialState;
  }
  handleChange = (input) => (e) => {
    if (e.target) {
      this.setState({
        [input]: e.target.value,
      });
    } else if (e.value) {
      this.setState({
        [input]: e.value,
      });
    } else {
      this.setState({
        [input]: e,
      });
    }
  };
  back = (e) => {
    e.preventDefault();
    if (this.state.workflow["dateCompleted"] === null) {
      this.props.history.push("/pesJob");
    } else {
      auth.login(() => {
        this.props.history.push("/");
      });
    }
  };
  componentDidMount() {
    getPESDetails({
      value: getKNC(this.cookie.access_token),
      PPPESID: this.state.workflow["parentEntityID"],
    })
      .then(({ data }) => {
        var pes_details = data[0];
        this.setState({
          apply_for:
            pes_details.applyFor === false &&
            this.state.workflow["dateCompleted"] === null
              ? ""
              : pes_details.applyFor === true
              ? "true"
              : "false",
          diarrhoea:
            pes_details.diarrhoea === false &&
            this.state.workflow["dateCompleted"] === null
              ? ""
              : pes_details.diarrhoea === true
              ? "true"
              : "false",
          skin_disorder:
            pes_details.skinDisorder === false &&
            this.state.workflow["dateCompleted"] === null
              ? ""
              : pes_details.skinDisorder === true
              ? "true"
              : "false",
          skin_disorder_details: pes_details.skinDisorderDetails
            ? pes_details.skinDisorderDetails
            : this.state.skin_disorder_details,
          weeks_four:
            pes_details.weeksFour === false &&
            this.state.workflow["dateCompleted"] === null
              ? ""
              : pes_details.weeksFour === true
              ? "true"
              : "false",
          weeks_four_details: pes_details.weeksFourDetails
            ? pes_details.weeksFourDetails
            : this.state.weeks_four_details,
          weeks_thirteen:
            pes_details.weeksThirteen === false &&
            this.state.workflow["dateCompleted"] === null
              ? ""
              : pes_details.weeksThirteen === true
              ? "true"
              : "false",
          weeks_thirteen_details: pes_details.weeksThirteenDetails
            ? pes_details.weeksThirteenDetails
            : this.state.weeks_thirteen_details,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.state.workflow["dateCompleted"] === null) {
      this.getNextworkflow();
    }
  }
  getNextworkflow = async () => {
    getNextWorkFlowDetails({
      workflowValues:
        this.state.workflow["targetEntityID"] +
        "," +
        this.state.workflow["targetEntityType"] +
        "," +
        this.state.workflow["wfeProcessID"],
    })
      .then(({ data }) => {
        console.log(data);
        this.setState({
          workflow: data["table"][0],
          nextWorkflow:
            data["table1"] !== [] ? data["table1"][0] : data["table1"],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  changeLoadingCircle = (flag) => {
    this.setState({ loadingCircle: flag });
    return true;
  };

  completeForm = (event) => {
    event.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.changeLoadingCircle(true);
      const schema = {
        pes_food_questionnaire: {
          UUID: this.state.KNC
            ? this.state.KNC
            : getKNC(this.cookie.access_token),
          ApplyFor: this.state.apply_for,
          Diarrhoea: this.state.diarrhoea === "" ? false : this.state.diarrhoea,
          SkinDisorder:
            this.state.skin_disorder === "" ? false : this.state.skin_disorder,
          SkinDisorderDetails: this.state.skin_disorder_details,
          WeeksFour:
            this.state.weeks_four === "" ? false : this.state.weeks_four,
          WeeksFourDetails: this.state.weeks_four_details,
          WeeksThirteen:
            this.state.weeks_thirteen === ""
              ? false
              : this.state.weeks_thirteen,
          WeeksThirteenDetails: this.state.weeks_thirteen_details,
          WorkflowID:
            this.state.workflow["dateCompleted"] === null
              ? this.state.workflow["wfeWorkflowID"]
              : -1,
          PPPESID: this.state.workflow["parentEntityID"],
        },
      };
      // implementing save after each page
      savePESFoodQuestionnaireDetails(schema.pes_food_questionnaire)
        .then(() => {
          //   event.preventDefault();
          //     this.props.nextStep();
          //    showPage(this.state.nextWorkflow, this.props);
          if (this.state.workflow["dateCompleted"] === null) {
            showPage(this.state.nextWorkflow, this.props);
          } else {
            showPage(this.state.workflow, this.props);
          }
          this.changeLoadingCircle(false);
        })
        .catch((error) => {
          window.alert(error);
          this.changeLoadingCircle(false);
        });
      this.setState({ submit: true });

      // createSaveWorkflow({
      //   UUID: getKNC(this.cookie.access_token),
      //   DateCompleted: new Date(),
      //   //processID: this.localStorageService.getWorkFlowId(),
      // })
      //   .then((response) => {
      //     this.changeLoadingCircle(false);
      //     auth.login(() => {
      //       this.props.history.push("/Home");
      //     });
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
  };
  validate = () => {
    let nameError = "";

    const val = this.state;
    if (
      val.diarrhoea === "" ||
      val.skin_disorder === "" ||
      (val.skin_disorder === "true" && val.skin_disorder_details === "") ||
      val.weeks_four === "" ||
      val.weeks_thirteen === ""
    ) {
      nameError = "This field is required";
    }
    if (nameError) {
      this.setState({ nameError });
      return false;
    } else {
      this.setState({
        nameError: "",
      });
      return true;
    }
  };
  RadiobtnStyle = (num, sptxt, type, tmpstate) => {
    return (
      <div
        className="custom-radio rounded"
        onClick={() => this.handleChange(type)}
      >
        <input
          type="radio"
          className="custom-input"
          value={num}
          name={type}
          checked={tmpstate === num.toString()}
          onChange={this.handleChange(type)}
        />
        <span>{sptxt}</span>
      </div>
    );
  };
  display = () => {
    const { loadingCircle } = this.props.pageProps;
    if (
      this.state.workflow &&
      this.state.workflow !== [] &&
      this.state.workflow["wfeModuleID"] === WFE_MODULE.FOOD_HANDLER
    ) {
      return (
        <div id="MainDiv">
          {this.state.loadingCircle === true ? loadingCircle : null}
          <div className="has-form">
            <div className="title">
              {this.state.workflow["dateCompleted"] === null && (
                <div className="div-progress-bar">
                  <p>{this.state.workflow.statusDisplay}</p>
                  <ProgressBar
                    animated
                    now={this.state.workflow.statusPercentage}
                  />
                </div>
              )}
              <h1>Food Handlers Questionnaire</h1>
            </div>
          </div>
          <div className="has-form">
            <div className="row">
              <div className="form-group">
                <div className="custom-radio-wrapper">
                  <label className="abc">
                    Have you suffered from any diarrhoea in the past 4 weeks?
                  </label>
                  <div className="errorMessage">
                    {this.state.diarrhoea === "" && this.state.nameError}
                  </div>
                  <RadioGroup>
                    {this.RadiobtnStyle(
                      "true",
                      "Yes",
                      "diarrhoea",
                      this.state.diarrhoea
                    )}
                    {this.RadiobtnStyle(
                      "false",
                      "No",
                      "diarrhoea",
                      this.state.diarrhoea
                    )}
                  </RadioGroup>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <div className="custom-radio-wrapper">
                  <label className="abc">
                    Are you currently suffering from any skin disorder, wound or
                    infection on your hands, forearm, face, head or neck?
                  </label>
                  <div className="errorMessage">
                    {this.state.skin_disorder === "" && this.state.nameError}
                  </div>
                  <RadioGroup>
                    {this.RadiobtnStyle(
                      "true",
                      "Yes",
                      "skin_disorder",
                      this.state.skin_disorder
                    )}
                    {this.RadiobtnStyle(
                      "false",
                      "No",
                      "skin_disorder",
                      this.state.skin_disorder
                    )}
                  </RadioGroup>
                </div>
                {this.state.skin_disorder === "true" && (
                  <div className="col-md-12 has-form">
                    <label className="abc">Tell us more</label>
                    <div className="errorMessage">
                      {this.state.skin_disorder === "true" &&
                        this.state.skin_disorder_details === "" &&
                        this.state.nameError}
                    </div>
                    <textarea
                      className="form-control"
                      rows="1"
                      cols="5"
                      onChange={this.handleChange("skin_disorder_details")}
                      value={this.state.skin_disorder_details}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="form-group">
                <div className="custom-radio-wrapper">
                  <label className="abc">
                    In the past 4 weeks have you suffered from any of the
                    following infectious diseases: Salmonella, Shigella, E.coli,
                    Hepatitis A?
                  </label>
                  <div className="errorMessage">
                    {this.state.weeks_four === "" && this.state.nameError}
                  </div>
                  <RadioGroup>
                    {this.RadiobtnStyle(
                      "true",
                      "Yes",
                      "weeks_four",
                      this.state.weeks_four
                    )}
                    {this.RadiobtnStyle(
                      "false",
                      "No",
                      "weeks_four",
                      this.state.weeks_four
                    )}
                  </RadioGroup>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <div className="custom-radio-wrapper">
                  <label className="abc">
                    Have you had treatment for ANY infectious or contagious
                    disease in the last 3 months?
                  </label>
                  <div className="errorMessage">
                    {this.state.weeks_thirteen === "" && this.state.nameError}
                  </div>
                  <RadioGroup>
                    {this.RadiobtnStyle(
                      "true",
                      "Yes",
                      "weeks_thirteen",
                      this.state.weeks_thirteen
                    )}
                    {this.RadiobtnStyle(
                      "false",
                      "No",
                      "weeks_thirteen",
                      this.state.weeks_thirteen
                    )}
                  </RadioGroup>
                </div>
              </div>
            </div>

            <div className="btn-block prev-back-btn">
              {/* <button className="btn btn-outline-primary" onClick={this.back}>
            Back
          </button> */}
              <button
                className="btn btn-primary modal-btn"
                data-modal-id="sampleModal"
                onClick={this.completeForm}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      auth.login(() => {
        this.props.history.push({
          pathname: "/",
        });
      });
    }
  };
  render() {
    return <div>{this.display()}</div>;
  }
}
export default FoodHandlersQuestionnaire;
