import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Landingpage extends Component {
  state = {};

  continue = (e) => {
    e.preventDefault();
  };

  render() {
    const { loginswitch } = this.props;
    return (
      <div id="MainDiv">
        <div className="landing-page-div">
          <img alt="" src={require("./WHAlogonew.png")} />
        </div>
        <div className="has-form">
          <div className="title">
            <h1 className="landing-page-heading">Your Health Manager</h1>
          </div>
        </div>
        <div className="row has-form">
          <h4>
            Welcome to Your Health Manager. <br /> <br />
            This application helps you to manage your information, and get ready
            for your appointment with Work Healthy Australia.
            <br /> <br />
            We make every effort to protect your information to ensure it is
            kept private, safe and secure.
          </h4>

          <div className="landing-page-login">
            <div className="btn-block">
              <button
                className="btn btn-primary btn-block"
                onClick={() => loginswitch()}
              >
                Login
              </button>
            </div>
            <div className="btn-block">
              <button
                className="btn btn-outline-primary btn-block"
                onClick={() => (window.location.href = "/Signup")}
              >
                Create your account
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Landingpage);
