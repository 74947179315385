export const DEFAULT_DATE = "1900-01-01";

export const WFE_MODULE = {
  PERSONAL_DETAILS: 1,
  CURRENT_EMPLOYMENT_DETAILS: 2,
  INJURY_DETAILS: 3,
  CORE_MEDICAL_HISTORY: 4,
  LIFESTYLE_MEDICAL_HISTORY: 5,
  PSYCHOLOGICAL_MEDICAL_HISTORY: 6,
  MUSCULOSKELETAL_SCREEN_MEDICAL_HISTORY: 7,
  RED_FLAG_MEDICAL_HISTORY: 8,
  FAMILY_HISTORY_MEDICAL_HISTORY: 9,
  MANUAL_HANDLING_SCREEN: 10,
  INDUSTRY_SPECIFIC_SCREEN: 11,
  PROM_OREBRO: 12,
  PROM_PSS_PERCEIVED_STRESS_SCALE: 13,
  PROM_FABQ_FEAR_AVOIDANCE_BELIEF_QUESTIONNAIRE: 14,
  PROM_FOSQ_FUNCTIONAL_OUTCOMES_OF_SLEEP_QUESTIONNAIRE: 15,
  PROM_PCS_PAIN_CATASTROPHISING_SCALE: 16,
  PROM_DASS21_DEPRESSION_ANXIETY_STRESS_SCALE: 17,
  PROM_NDS_NECK_DISABILITY_INDEX: 18,
  PROM_LEFS_LOWER_EXTREMITY_FUNCTIONAL_SCALE: 19,
  PROM_QBPD_QUEBEC_BACK_PAIN_DISABILITY_SCALE: 20,
  PROM_DASH_DISABILITY_OF_THE_ARM_SHOULDER_HAND: 21,
  JOB_DETAILS: 22,
  JOB_SOP: 23,
  TASK_DETAILS: 24,
  TASK_QUESTIONS: 25,
  TASK_OVERALL_DEMAND: 26,
  TASK_MINIMUM_OBJECTIVE_STRENGTH: 27,
  TASK_MANTRA: 28,
  TASK_GRIP_DEMANDS: 29,
  TASK_POSTURAL_DEMANDS: 30,
  TASK_MANUAL_HANDLING_DEMANDS: 31,
  TASK_BODY_DEMANDS: 32,
  TASK_RISK_AUDIT: 33,
  TASK_WORK_METHOD_STATEMENT: 34,
  TASK_PPE: 35,
  TASK_RISK_HAZARD_ASSESSMENT: 36,
  TASK_VIDEO_RECORDED: 37,
  FOOD_HANDLER: 38,
  JOB_HISTORY_DETAILS: 39,
};

export const WFE_PROCESS = {
  NEW_PATIENT_WITH_INJURY: 1,
  RETURN_PATIENT_EXISTING_INJURY: 2,
  PES_CANDIDATE: 3,
  ACCOUNT_REGISTRATION: 4,
  PROM: 5,
  // ONBOARD_CARE_PLAN: 7,
  //REGISTERED_PATIENT_NEW_INJURY_NO_MH: 6,
  NEW_CARE_PLAN: 6,
  NEW_PES: 7,
  PERSONAL_DETAILS_REVIEW: 8,
};

export const WFE_STEPORDER = {
  PERSONAL_DETAILS: 1,
  CORE_MEDICAL_HISTORY: 21,
  FAMILY_HISTORY_MEDICAL_HISTORY: 22,
  LIFESTYLE_MEDICAL_HISTORY: 23,
  RED_FLAG_MEDICAL_HISTORY: 24,
  INDUSTRY_SPECIFIC_SCREEN: 25,
  JOB_HISTORY_DETAILS: 31,
  FOOD_HANDLER: 32,
  INJURY_DETAILS: 31,
};

export const ETHNICITY_OPTIONS = [
  { label: "Acehnese", value: 5208 },
  { label: "Acholi", value: 9111 },
  { label: "Afghan", value: 7201 },
  { label: "African American", value: 8101 },
  { label: "Afrikaner", value: 9201 },
  { label: "Akan", value: 9101 },
  { label: "Albanian", value: 3201 },
  { label: "Algerian", value: 4101 },
  { label: "American", value: 8102 },
  { label: "Amhara", value: 9222 },
  { label: "Anglo-Burmese", value: 5101 },
  { label: "Anglo-Indian", value: 7101 },
  { label: "Angolan", value: 9202 },
  { label: "Arab; other", value: 4199 },
  { label: "Argentinian", value: 8201 },
  { label: "Armenian", value: 7202 },
  { label: "Assyrian", value: 4908 },
  { label: "Australian", value: 1101 },
  { label: "Australian Aboriginal", value: 1102 },
  { label: "Australian South Sea Islander", value: 1103 },
  { label: "Austrian", value: 2301 },
  { label: "Azeri", value: 7207 },
  { label: "Bahraini", value: 4116 },
  { label: "Balinese", value: 5211 },
  { label: "Bangladeshi", value: 7118 },
  { label: "Barbadian", value: 8404 },
  { label: "Bari", value: 4301 },
  { label: "Basque", value: 3101 },
  { label: "Batswana", value: 9223 },
  { label: "Belarusan", value: 3301 },
  { label: "Belgian", value: 2311 },
  { label: "Bengali", value: 7102 },
  { label: "Berber", value: 4902 },
  { label: "Bermudan", value: 8107 },
  { label: "Bhutanese", value: 7121 },
  { label: "Bolivian", value: 8202 },
  { label: "Bosnian", value: 3202 },
  { label: "Brazilian", value: 8203 },
  { label: "British; other", value: 2199 },
  { label: "Bruneian", value: 5212 },
  { label: "Bulgarian", value: 3203 },
  { label: "Burgher", value: 7104 },
  { label: "Burmese", value: 5102 },
  { label: "Burundian", value: 9233 },
  { label: "Cameroonian", value: 9112 },
  { label: "Canadian", value: 8103 },
  { label: "Caribbean Islander; other", value: 8499 },
  { label: "Catalan", value: 3102 },
  { label: "Central American; other", value: 8399 },
  { label: "Central and West African; other", value: 9199 },
  { label: "Central Asian; other", value: 7299 },
  { label: "Chaldean", value: 4911 },
  { label: "Channel Islander", value: 2104 },
  { label: "Chilean", value: 8204 },
  { label: "Chin", value: 5112 },
  { label: "Chinese", value: 6101 },
  { label: "Chinese Asian; other", value: 6199 },
  { label: "Colombian", value: 8205 },
  { label: "Congolese", value: 9113 },
  { label: "Cook Islander", value: 1501 },
  { label: "Coptic", value: 4903 },
  { label: "Costa Rican", value: 8304 },
  { label: "Croatian", value: 3204 },
  { label: "Cuban", value: 8401 },
  { label: "Cypriot", value: 3215 },
  { label: "Czech", value: 3302 },
  { label: "Danish", value: 2401 },
  { label: "Darfur", value: 4302 },
  { label: "Dinka", value: 4303 },
  { label: "Dutch", value: 2303 },
  { label: "Eastern European; other", value: 3399 },
  { label: "Ecuadorian", value: 8206 },
  { label: "Egyptian", value: 4102 },
  { label: "Emirati", value: 4117 },
  { label: "English", value: 2101 },
  { label: "Eritrean", value: 9203 },
  { label: "Estonian", value: 3303 },
  { label: "Ethiopian", value: 9204 },
  { label: "Fijian", value: 1502 },
  { label: "Fijian Indian", value: 7122 },
  { label: "Filipino", value: 5201 },
  { label: "Finnish", value: 2402 },
  { label: "Flemish", value: 2304 },
  { label: "French", value: 2305 },
  { label: "French Canadian", value: 8104 },
  { label: "Frisian", value: 2312 },
  { label: "Fulani", value: 9102 },
  { label: "Georgian", value: 7203 },
  { label: "German", value: 2306 },
  { label: "Ghanaian", value: 9103 },
  { label: "Gibraltarian", value: 3107 },
  { label: "Gio", value: 9114 },
  { label: "Greek", value: 3205 },
  { label: "Guatemalan", value: 8305 },
  { label: "Gujarati", value: 7105 },
  { label: "Guyanese", value: 8207 },
  { label: "Hawaiian", value: 1506 },
  { label: "Hazara", value: 7208 },
  { label: "Hispanic North American", value: 8105 },
  { label: "Hmong", value: 5103 },
  { label: "Hungarian", value: 3304 },
  { label: "Hutu", value: 9225 },
  { label: "Icelandic", value: 2403 },
  { label: "Igbo", value: 9115 },
  { label: "I-Kiribati", value: 1401 },
  { label: "Indian", value: 7106 },
  { label: "Indian Tamil", value: 7128 },
  { label: "Indonesian", value: 5202 },
  { label: "Iranian", value: 4904 },
  { label: "Iraqi", value: 4103 },
  { label: "Irish", value: 2201 },
  { label: "Italian", value: 3103 },
  { label: "Ivorean", value: 9106 },
  { label: "Jamaican", value: 8402 },
  { label: "Japanese", value: 6901 },
  { label: "Javanese", value: 5203 },
  { label: "Jewish", value: 4201 },
  { label: "Jordanian", value: 4104 },
  { label: "Kadazan", value: 5213 },
  { label: "Karen", value: 5108 },
  { label: "Kashmiri", value: 7123 },
  { label: "Kazakh", value: 7204 },
  { label: "Kenyan", value: 9205 },
  { label: "Khmer (Cambodian)", value: 5104 },
  { label: "Korean", value: 6902 },
  { label: "Krahn", value: 9116 },
  { label: "Kunama", value: 9234 },
  { label: "Kurdish", value: 4905 },
  { label: "Kuwaiti", value: 4105 },
  { label: "Kyrgyz", value: 7215 },
  { label: "Lao", value: 5105 },
  { label: "Latvian", value: 3305 },
  { label: "Lebanese", value: 4106 },
  { label: "Liberian", value: 9107 },
  { label: "Libyan", value: 4107 },
  { label: "Lithuanian", value: 3306 },
  { label: "Luxembourg", value: 2313 },
  { label: "Macedonian", value: 3206 },
  { label: "Madi", value: 9235 },
  { label: "Madurese", value: 5204 },
  { label: "Mainland South-East Asian; other", value: 5199 },
  { label: "Malawian", value: 9206 },
  { label: "Malay", value: 5205 },
  { label: "Malayali", value: 7107 },
  { label: "Maldivian", value: 7117 },
  { label: "Maltese", value: 3104 },
  { label: "Mandaean", value: 4912 },
  { label: "Mandinka", value: 9117 },
  { label: "Manx", value: 2105 },
  { label: "Maori", value: 1201 },
  { label: "Maritime South-East Asian; other", value: 5299 },
  { label: "Masai", value: 9226 },
  { label: "Mauritian", value: 9207 },
  { label: "Mayan", value: 8306 },
  { label: "Melanesian and Papuan; other", value: 1399 },
  { label: "Mexican", value: 8301 },
  { label: "Micronesian; other", value: 1403 },
  { label: "Moldovan", value: 3207 },
  { label: "Mon", value: 5111 },
  { label: "Mongolian", value: 6903 },
  { label: "Montenegrin", value: 3208 },
  { label: "Moroccan", value: 4108 },
  { label: "Mozambican", value: 9208 },
  { label: "Namibian", value: 9211 },
  { label: "Native North American Indian", value: 8106 },
  { label: "Nauruan", value: 1402 },
  { label: "Nepalese", value: 7111 },
  { label: "New Caledonian", value: 1301 },
  { label: "New Zealander", value: 1202 },
  { label: "Nicaraguan", value: 8302 },
  { label: "Nigerian", value: 9104 },
  { label: "Niuean", value: 1503 },
  { label: "Ni-Vanuatu", value: 1302 },
  { label: "North American; other", value: 8199 },
  { label: "Northern European; other", value: 2499 },
  { label: "Norwegian", value: 2404 },
  { label: "Nubian", value: 4913 },
  { label: "Nuer", value: 4304 },
  { label: "Ogaden", value: 9236 },
  { label: "Omani", value: 4118 },
  { label: "Oromo", value: 9212 },
  { label: "Other North African and Middle Eastern; other", value: 4999 },
  { label: "Other North-East Asian; other", value: 6999 },
  { label: "Pakistani", value: 7112 },
  { label: "Palestinian", value: 4111 },
  { label: "Papua New Guinean", value: 1303 },
  { label: "Paraguayan", value: 8213 },
  { label: "Parsi", value: 7124 },
  { label: "Pathan", value: 7205 },
  { label: "Peoples of the Sudan; other", value: 4399 },
  { label: "Peruvian", value: 8208 },
  { label: "Pitcairn", value: 1512 },
  { label: "Polish", value: 3307 },
  { label: "Polynesian; other", value: 1599 },
  { label: "Portuguese", value: 3105 },
  { label: "Puerto Rican", value: 8405 },
  { label: "Punjabi", value: 7113 },
  { label: "Qatari", value: 4121 },
  { label: "Rohingya", value: 5113 },
  { label: "Roma Gypsy", value: 3212 },
  { label: "Romanian", value: 3211 },
  { label: "Russian", value: 3308 },
  { label: "Rwandan", value: 9237 },
  { label: "Salvadoran", value: 8303 },
  { label: "Samoan", value: 1504 },
  { label: "Saudi Arabian", value: 4112 },
  { label: "Scottish", value: 2102 },
  { label: "Senegalese", value: 9118 },
  { label: "Serbian", value: 3213 },
  { label: "Seychellois", value: 9213 },
  { label: "Shona", value: 9238 },
  { label: "Sierra Leonean", value: 9108 },
  { label: "Sikh", value: 7114 },
  { label: "Sindhi", value: 7125 },
  { label: "Singaporean", value: 5214 },
  { label: "Sinhalese", value: 7115 },
  { label: "Slovak", value: 3311 },
  { label: "Slovene", value: 3214 },
  { label: "Solomon Islander", value: 1304 },
  { label: "Somali", value: 9214 },
  { label: "Sorb/Wend", value: 3313 },
  { label: "South African", value: 9215 },
  { label: "South American; other", value: 8299 },
  { label: "South Eastern European; other", value: 3299 },
  { label: "South Sudanese", value: 4305 },
  { label: "Southern and East African; other", value: 9299 },
  { label: "Southern Asian; other", value: 7199 },
  { label: "Southern European; other", value: 3199 },
  { label: "Spanish", value: 3106 },
  { label: "Sri Lankan", value: 7126 },
  { label: "Sri Lankan Tamil", value: 7127 },
  { label: "Sudanese", value: 4306 },
  { label: "Sundanese", value: 5206 },
  { label: "Swahili", value: 9241 },
  { label: "Swazilander", value: 9242 },
  { label: "Swedish", value: 2405 },
  { label: "Swiss", value: 2307 },
  { label: "Syrian", value: 4113 },
  { label: "Tahitian", value: 1507 },
  { label: "Taiwanese", value: 6102 },
  { label: "Tajik", value: 7211 },
  { label: "Tamil", value: 7131 },
  { label: "Tanzanian", value: 9216 },
  { label: "Tatar", value: 7212 },
  { label: "Telugu", value: 7132 },
  { label: "Temoq", value: 5215 },
  { label: "Thai", value: 5106 },
  { label: "Themne", value: 9121 },
  { label: "Tibetan", value: 6904 },
  { label: "Tigrayan", value: 9228 },
  { label: "Tigre", value: 9231 },
  { label: "Timorese", value: 5207 },
  { label: "Togolese", value: 9122 },
  { label: "Tokelauan", value: 1508 },
  { label: "Tongan", value: 1505 },
  { label: "Torres Strait Islander", value: 1104 },
  { label: "Trinidadian Tobagonian", value: 8403 },
  { label: "Tunisian", value: 4114 },
  { label: "Turkish", value: 4907 },
  { label: "Turkmen", value: 7213 },
  { label: "Tuvaluan", value: 1511 },
  { label: "Ugandan", value: 9217 },
  { label: "Uighur", value: 7214 },
  { label: "Ukrainian", value: 3312 },
  { label: "Uruguayan", value: 8211 },
  { label: "Uzbek", value: 7206 },
  { label: "Venezuelan", value: 8212 },
  { label: "Vietnamese", value: 5107 },
  { label: "Vlach", value: 3216 },
  { label: "Welsh", value: 2103 },
  { label: "Western European; other", value: 2399 },
  { label: "Yemeni", value: 4115 },
  { label: "Yezidi", value: 4914 },
  { label: "Yoruba", value: 9105 },
  { label: "Zambian", value: 9218 },
  { label: "Zimbabwean", value: 9221 },
  { label: "Zulu", value: 9232 },
];

export const STATE_OPTIONS = [
  { label: "ACT", value: 6 },
  { label: "NSW", value: 3 },
  { label: "NT", value: 4 },
  { label: "QLD", value: 1 },
  { label: "SA", value: 7 },
  { label: "TAS", value: 5 },
  { label: "VIC", value: 2 },
  { label: "WA", value: 8 },
];

export const BackMAP = {
  //joint: "my-map",
  orientation: "Back",
  areas: [
    {
      jointId: 26,
      joint: "Head",
      desc: "Right head back",
      side: "Right",
      shape: "rect",
      coords: [95, 6, 125, 28],
    }, //0
    {
      jointId: 26,
      joint: "Head",
      desc: "Left head back",
      side: "Left",
      shape: "rect",
      coords: [125, 6, 155, 28],
    }, //1
    {
      jointId: 26,
      joint: "Head",
      desc: "Central head back",
      side: "Central",
      shape: "rect",
      coords: [95, 30, 155, 56],
    }, //2

    {
      jointId: 1,
      joint: "Cervical",
      desc: "Cervical back",
      side: "Central",
      shape: "rect",
      coords: [95, 57, 155, 75],
    }, //3
    {
      jointId: 21,
      joint: "Cervicothoracic",
      desc: "Cervicothoracic",
      side: "Central",
      shape: "rect",
      coords: [95, 75, 155, 90],
    }, //4

    {
      jointId: 7,
      joint: "Shoulder",
      desc: "Left shoulder back",
      side: "Left",
      shape: "rect",
      coords: [163, 88, 211, 130],
    }, //5
    {
      jointId: 7,
      joint: "Shoulder",
      desc: "Right shoulder back",
      side: "Right",
      shape: "rect",
      coords: [44, 88, 92, 130],
    }, //6

    {
      jointId: 2,
      joint: "Thoracic",
      desc: "Left thoracic",
      side: "Left",
      shape: "rect",
      coords: [92, 88, 127, 130],
    }, //7
    {
      jointId: 2,
      joint: "Thoracic",
      desc: "Right thoracic",
      side: "Right",
      shape: "rect",
      coords: [127, 88, 163, 130],
    }, //8

    {
      jointId: 13,
      joint: "Upper Arm",
      desc: "Right upper arm back",
      side: "Right",
      shape: "rect",
      coords: [170, 130, 210, 170],
    }, //9

    {
      jointId: 13,
      joint: "Upper Arm",
      desc: "Left upper arm back",
      side: "Left",
      shape: "rect",
      coords: [44, 130, 80, 170],
    }, //10

    {
      jointId: 19,
      joint: "Ribs",
      desc: "Left ribs back",
      side: "Left",
      shape: "rect",
      coords: [85, 130, 127, 170],
    }, //11
    {
      jointId: 19,
      joint: "Ribs",
      desc: "Right ribs back",
      side: "Right",
      shape: "rect",
      coords: [127, 130, 170, 170],
    }, //12
    {
      jointId: 2,
      joint: "Thoracic",
      desc: "Central thoracic",
      side: "Central",
      shape: "rect",
      coords: [127, 130, 170, 170],
    }, //13

    {
      jointId: 8,
      joint: "Elbow",
      desc: "Left elbow back",
      side: "Left",
      shape: "rect",
      coords: [30, 170, 80, 200],
    }, //14
    {
      jointId: 22,
      joint: "Thoracolumbar",
      desc: "Thoracolumbar",
      side: "Central",
      shape: "rect",
      coords: [80, 170, 175, 240],
    },
    {
      jointId: 8,
      joint: "Elbow",
      desc: "Right elbow back",
      side: "Right",
      shape: "rect",
      coords: [170, 170, 220, 200],
    },

    {
      jointId: 12,
      joint: "Forearm",
      desc: "Left forearm back",
      side: "Left",
      shape: "rect",
      coords: [20, 190, 80, 240],
    }, //17
    {
      jointId: 12,
      joint: "Forearm",
      desc: "Right forearm back",
      side: "Right",
      shape: "rect",
      coords: [175, 190, 240, 240],
    }, //18

    {
      jointId: 23,
      joint: "Lumbosacral",
      desc: "Left lumbosacral",
      side: "Left",
      shape: "rect",
      coords: [175, 190, 240, 240],
    }, //19
    {
      jointId: 23,
      joint: "Lumbosacral",
      desc: "Right lumbosacral",
      side: "Right",
      shape: "rect",
      coords: [175, 190, 240, 240],
    }, //20

    {
      jointId: 9,
      joint: "Wrist",
      desc: "Left wrist back",
      side: "Left",
      shape: "rect",
      coords: [24, 240, 60, 270],
    }, //21
    {
      jointId: 9,
      joint: "Wrist",
      desc: "Right wrist back",
      side: "Right",
      shape: "rect",
      coords: [200, 240, 240, 270],
    }, //22

    {
      jointId: 18,
      joint: "Pelvis",
      desc: "Left pelvis back",
      side: "Left",
      shape: "rect",
      coords: [80, 240, 130, 270],
    }, //23
    {
      jointId: 18,
      joint: "Pelvis",
      desc: "Right pelvis back",
      side: "Right",
      shape: "rect",
      coords: [130, 240, 180, 270],
    }, //24

    {
      jointId: 10,
      joint: "Thumb",
      desc: "Right thumb back",
      side: "Right",
      shape: "rect",
      coords: [8, 270, 20, 300],
    }, // 25
    {
      jointId: 10,
      joint: "Thumb",
      desc: "Left thumb back",
      side: "Left",
      shape: "rect",
      coords: [240, 270, 250, 300],
    }, //26

    {
      jointId: 20,
      joint: "Hand",
      desc: "Right hand back",
      side: "Right",
      shape: "rect",
      coords: [25, 270, 50, 290],
    }, //27
    {
      jointId: 20,
      joint: "Hand",
      desc: "Left hand back",
      side: "Left",
      shape: "rect",
      coords: [205, 270, 230, 290],
    }, //28

    {
      jointId: 4,
      joint: "Hip",
      desc: "Right hip back",
      side: "Right",
      shape: "rect",
      coords: [80, 270, 130, 285],
    }, //29
    {
      jointId: 4,
      joint: "Hip",
      desc: "Left hip back",
      side: "Left",
      shape: "rect",
      coords: [125, 270, 178, 285],
    }, //30

    {
      jointId: 11,
      joint: "Fingers",
      desc: "Right fingers back",
      side: "Right",
      shape: "rect",
      coords: [15, 290, 50, 320],
    }, //31
    {
      jointId: 11,
      joint: "Fingers",
      desc: "Left fingers back",
      side: "Left",
      shape: "rect",
      coords: [210, 285, 240, 315],
    }, //32

    {
      jointId: 14,
      joint: "Upper Leg",
      desc: "Right upper leg back",
      side: "Right",
      shape: "rect",
      coords: [75, 280, 128, 360],
    }, //33
    {
      jointId: 14,
      joint: "Upper Leg",
      desc: "Left upper leg back",
      side: "Left",
      shape: "rect",
      coords: [127, 280, 180, 360],
    }, //34

    {
      jointId: 5,
      joint: "Knee",
      desc: "Right knee back",
      side: "Right",
      shape: "rect",
      coords: [85, 360, 130, 400],
    }, //35
    {
      jointId: 5,
      joint: "Knee",
      desc: "Left knee back",
      side: "Left",
      shape: "rect",
      coords: [127, 360, 170, 400],
    }, //36

    //{ joint: "Pelvis  ", shape: "rect", coords: [80,240,130,270 ]},

    {
      jointId: 15,
      joint: "Lower Leg",
      desc: "Right lower leg back",
      side: "Right",
      shape: "rect",
      coords: [90, 400, 130, 470],
    }, // 37
    {
      jointId: 15,
      joint: "Lower Leg",
      desc: "Left lower leg back",
      side: "Left",
      shape: "rect",
      coords: [130, 400, 170, 470],
    }, // 38
    {
      jointId: 6,
      joint: "Ankle",
      desc: "Right ankle back",
      side: "Right",
      shape: "rect",
      coords: [100, 470, 135, 490],
    }, //39
    {
      jointId: 6,
      joint: "Ankle",
      desc: "Left ankle back",
      side: "Left",
      shape: "rect",
      coords: [125, 470, 160, 490],
    }, //40
    {
      jointId: 16,
      joint: "Foot",
      desc: "Right foot back",
      side: "Right",
      shape: "rect",
      coords: [100, 485, 130, 495],
    }, //41
    {
      jointId: 16,
      joint: "Foot",
      desc: "Left foot back",
      side: "Left",
      shape: "rect",
      coords: [130, 485, 160, 500],
    }, //42
    {
      jointId: 17,
      joint: "Toes",
      desc: "Right toes back",
      side: "Right",
      shape: "rect",
      coords: [95, 496, 135, 510],
    }, //43
    {
      jointId: 17,
      joint: "Left",
      desc: "Left toes back",
      side: "Left",
      shape: "rect",
      coords: [130, 496, 167, 510],
    }, //44
  ],
};

export const MAP = {
  //joint: "my-map",
  orientation: "Front",
  areas: [
    {
      jointId: 26,
      desc: "Right head front",
      joint: "Head",
      side: "Right",
      shape: "rect",
      coords: [95, 6, 125, 28],
    }, //0
    {
      jointId: 26,
      desc: "Left head front",
      joint: "Head",
      side: "Left",
      shape: "rect",
      coords: [125, 6, 155, 28],
    }, //1
    {
      jointId: 26,
      desc: "Central head front",
      joint: "Head",
      side: "Central",
      shape: "rect",
      coords: [95, 30, 155, 56],
    }, //2

    {
      jointId: 24,
      joint: "Jaw",
      desc: "Jaw front",
      side: "Central",
      shape: "rect",
      coords: [95, 57, 155, 75],
    }, //3

    {
      jointId: 1,
      joint: "Cervical",
      desc: "Cervial front",
      side: "Central",
      shape: "rect",
      coords: [95, 75, 155, 90],
    }, //4
    {
      jointId: 7,
      joint: "Shoulder",
      desc: "Right shoulder front",
      side: "Right",
      shape: "rect",
      coords: [44, 88, 92, 130],
    }, //5
    {
      jointId: 7,
      joint: "Shoulder",
      desc: "Left shoulder front",
      side: "Left",
      shape: "rect",
      coords: [163, 88, 211, 130],
    }, //6
    {
      jointId: 25,
      joint: "Chest",
      desc: "Right chest front",
      side: "Right",
      shape: "rect",
      coords: [92, 88, 127, 130],
    }, //7
    {
      jointId: 25,
      joint: "Chest",
      desc: "Left chest front",
      side: "Left",
      shape: "rect",
      coords: [127, 88, 163, 130],
    }, //8

    {
      jointId: 13,
      joint: "Arm",
      desc: "Left arm front",
      side: "Left",
      shape: "rect",
      coords: [44, 130, 80, 170],
    }, //9
    {
      jointId: 13,
      joint: "Arm",
      desc: "Right arm front",
      side: "Right",
      shape: "rect",
      coords: [170, 130, 210, 170],
    }, //10

    {
      jointId: 19,
      joint: "Ribs",
      desc: "Right ribs front",
      side: "Right",
      shape: "rect",
      coords: [85, 130, 127, 170],
    }, //11
    {
      jointId: 19,
      joint: "Ribs",
      desc: "Left ribs front",
      side: "Left",
      shape: "rect",
      coords: [127, 130, 170, 170],
    }, //12

    {
      jointId: 8,
      joint: "Elbow",
      desc: "Right elbow front",
      side: "Right",
      shape: "rect",
      coords: [30, 170, 80, 200],
    }, //13
    {
      jointId: 8,
      joint: "Elbow",
      desc: "Left elbow front",
      side: "Left",
      shape: "rect",
      coords: [170, 170, 220, 200],
    }, //14

    {
      jointId: 27,
      joint: "Abdomen",
      desc: "Abdomen front",
      side: "Central",
      shape: "rect",
      coords: [80, 170, 175, 240],
    }, //15

    {
      jointId: 12,
      joint: "Forearm",
      desc: "Right forearm front",
      side: "Right",
      shape: "rect",
      coords: [20, 190, 80, 240],
    }, //16
    {
      jointId: 12,
      joint: "Forearm",
      desc: "Left forearm front",
      side: "Left",
      shape: "rect",
      coords: [175, 190, 240, 240],
    }, //17

    {
      jointId: 9,
      joint: "Wrist",
      desc: "Left wrist front",
      side: "Left",
      shape: "rect",
      coords: [24, 240, 60, 270],
    }, // 18
    {
      jointId: 9,
      joint: "Wrist",
      desc: "Right wrist front",
      side: "Right",
      shape: "rect",
      coords: [200, 240, 240, 270],
    }, //19

    {
      jointId: 18,
      joint: "Pelvis",
      desc: "Right pelvis front",
      side: "Right",
      shape: "rect",
      coords: [80, 240, 130, 270],
    }, //20
    {
      jointId: 18,
      joint: "Pelvis",
      desc: "Left pelvis front ",
      side: "Left",
      shape: "rect",
      coords: [130, 240, 180, 270],
    }, //21

    {
      jointId: 10,
      joint: "Thumb",
      desc: "Right thumb front",
      side: "Right",
      shape: "rect",
      coords: [8, 270, 20, 300],
    }, //22
    {
      jointId: 10,
      joint: "Thumb",
      desc: "Left thumb front ",
      side: "Left",
      shape: "rect",
      coords: [240, 270, 250, 300],
    }, //23

    {
      jointId: 20,
      joint: "Hand",
      desc: "Left hand front",
      side: "Left",
      shape: "rect",
      coords: [205, 270, 230, 290],
    }, //24

    {
      jointId: 20,
      joint: "Hand",
      desc: "Right hand front",
      side: "Right",
      shape: "rect",
      coords: [25, 270, 50, 290],
    }, //25

    {
      jointId: 4,
      joint: "Hip",
      desc: "Right hip front",
      side: "Right",
      shape: "rect",
      coords: [80, 270, 130, 285],
    }, //26
    {
      jointId: 4,
      joint: "Hip",
      desc: "Left hip front",
      side: "Left",
      shape: "rect",
      coords: [125, 270, 178, 285],
    }, //27

    {
      jointId: 11,
      joint: "Fingers",
      desc: "Right fingers front",
      side: "Right",
      shape: "rect",
      coords: [15, 290, 50, 320],
    }, //28
    {
      jointId: 11,
      joint: "Fingers",
      desc: "Left fingers front ",
      side: "Left",
      shape: "rect",
      coords: [210, 285, 240, 315],
    }, //29

    {
      jointId: 14,
      joint: "Upper Leg",
      desc: "Right upper leg front",
      side: "Right",
      shape: "rect",
      coords: [75, 280, 128, 360],
    }, //30
    {
      jointId: 14,
      joint: "Upper Leg",
      desc: "Left upper leg front",
      side: "Left",
      shape: "rect",
      coords: [127, 280, 180, 360],
    }, //31

    {
      jointId: 5,
      joint: "Knee",
      desc: "Right knee front",
      side: "Right",
      shape: "rect",
      coords: [85, 360, 130, 400],
    }, //32
    {
      jointId: 5,
      joint: "Knee",
      desc: "Left knee front",
      side: "Left",
      shape: "rect",
      coords: [127, 360, 170, 400],
    }, //33

    {
      jointId: 15,
      joint: "Lower Leg",
      desc: "Right lower leg front",
      side: "Right",
      shape: "rect",
      coords: [90, 400, 130, 470],
    }, //34

    {
      jointId: 15,
      joint: "Lower Leg",
      desc: "Left lower leg front",
      side: "Left",
      shape: "rect",
      coords: [130, 400, 170, 470],
    }, // 35
    {
      jointId: 6,
      joint: "Ankle",
      desc: "Right ankle front",
      side: "Right",
      shape: "rect",
      coords: [100, 470, 135, 490],
    }, //36
    {
      jointId: 6,
      joint: "Ankle",
      desc: "Left ankle front",
      side: "Left",
      shape: "rect",
      coords: [125, 470, 160, 490],
    }, //37
    {
      jointId: 16,
      joint: "Foot",
      desc: "Right foot front",
      side: "Right",
      shape: "rect",
      coords: [100, 485, 130, 495],
    }, //38
    {
      jointId: 16,
      joint: "Foot",
      desc: "Left foot front",
      side: "Left",
      shape: "rect",
      coords: [130, 485, 160, 500],
    }, //39
    {
      jointId: 17,
      joint: "Toes",
      desc: "Right toes front",
      side: "Right",
      shape: "rect",
      coords: [95, 496, 135, 510],
    }, //40
    {
      jointId: 17,
      joint: "Toes",
      desc: "Left toes front",
      side: "Left",
      shape: "rect",
      coords: [130, 496, 167, 510],
    }, //41
  ],
};

export const AGGRAVATING_OPTIONS = [
  // { label: "Abduction", value: 1 },
  // { label: "Adduction", value: 2 },
  // { label: "At rest", value: 48 },
  { label: "Bending", value: 24 },
  { label: "Breathing", value: 28 },
  { label: "Bright lights", value: 29 },
  { label: "Carrying", value: 30 },
  // { label: "Circumduction", value: 20 },
  { label: "Climbing", value: 33 },
  { label: "Computer Work", value: 39 },
  { label: "Coughing", value: 34 },
  // { label: "Daily activities", value: 46 },
  // { label: "Descending", value: 36 },
  // { label: "Dorsiflexion", value: 3 },
  { label: "Driving", value: 40 },
  // { label: "Environmental factors", value: 45 },
  // { label: "Eversion", value: 4 },
  // { label: "Excessive load", value: 37 },
  // { label: "Excessive use", value: 38 },
  // { label: "Extension", value: 10 },
  // { label: "External rotation", value: 18 },
  // { label: "Flexion", value: 7 },
  // { label: "Grasping", value: 16 },
  { label: "Gripping", value: 17 },
  // { label: "Horizontal adduction", value: 21 },
  // { label: "Internal rotation", value: 19 },
  // { label: "Inversion", value: 5 },
  // { label: "Lateral flexion", value: 11 },
  { label: "Lifting", value: 25 },
  { label: "Looking down", value: 42 },
  { label: "Looking up", value: 43 },
  // { label: "Lying down", value: 44 },
  { label: "Movement", value: 47 },
  // { label: "Nothing Reported", value: 59 },
  { label: "Overhead work", value: 27 },
  { label: "Pinching", value: 50 },
  // { label: "Plantar Flexion", value: 6 },
  // { label: "Pronation", value: 9 },
  // { label: "Protraction", value: 14 },
  { label: "Pulling", value: 32 },
  { label: "Pushing", value: 31 },
  // { label: "Radial deviation", value: 22 },
  { label: "Reaching", value: 49 },
  { label: "Repetition", value: 13 },
  // { label: "Retraction", value: 15 },
  // { label: "Rotation", value: 12 },
  { label: "Sitting", value: 52 },
  { label: "Sneezing", value: 35 },
  { label: "Squatting", value: 55 },
  { label: "Stairs", value: 56 },
  { label: "Standing", value: 51 },
  // { label: "Supination", value: 8 },
  { label: "Touch", value: 58 },
  { label: "Twisting", value: 26 },
  // { label: "Ulnar deviation", value: 23 },
  { label: "Vibration", value: 57 },
  { label: "Walking", value: 41 },
  // { label: "Weight bearing", value: 54 },
];

export const RELIEVING_OPTIONS = [
  { label: "Acupuncture", value: 7 },
  { label: "Alcohol", value: 8 },
  { label: "Bandaging", value: 9 },
  { label: "Changing positions", value: 11 },
  { label: "Chiropractor", value: 12 },
  { label: "Cold", value: 13 },
  { label: "Elevation", value: 16 },
  { label: "Extension", value: 17 },
  { label: "Flexion", value: 18 },
  { label: "Heat", value: 14 },
  { label: "Ice", value: 35 },
  { label: "Light duties", value: 20 },
  { label: "Lying flat", value: 19 },
  { label: "Magnets", value: 24 },
  { label: "Manipulation", value: 22 },
  { label: "Massage", value: 23 },
  { label: "Medication - Anti-inflammatories", value: 3 },
  { label: "Medication - NSAIDs", value: 5 },
  { label: "Medication - Other", value: 6 },
  { label: "Medication - Pain Killers", value: 2 },
  { label: "Medication - Prescribed", value: 4 },
  { label: "Neutral positions", value: 21 },
  { label: "Nil", value: 1 },
  { label: "Non-weight bearing", value: 25 },
  { label: "Not performing aggravating movement", value: 26 },
  { label: "Nothing Reported", value: 34 },
  { label: "Physical exercise", value: 32 },
  { label: "Rest", value: 10 },
  { label: "Rubbing", value: 30 },
  { label: "Strapping", value: 15 },
  { label: "Stretching", value: 29 },
  { label: "Support (braces)", value: 33 },
  { label: "TENS", value: 31 },
];

export const SYMPTOM_OCCURENCE = [
  { label: "Constantly", value: 1 },
  { label: "Frequently", value: 2 },
  { label: "Occasionally", value: 3 },
  { label: "Often", value: 4 },
  { label: "Rarely", value: 5 },
];
export const WORST_TIME = [
  // { label: "After exercise", value: 10 },
  { label: "After movement", value: 23 },
  // { label: "After use", value: 9 },
  // { label: "After work", value: 12 },
  // { label: "Afternoon", value: 7 },
  { label: "At night", value: 21 },
  // { label: "At rest", value: 1 },
  // { label: "At the end of the day", value: 4 },
  // { label: "At the start of the day", value: 3 },
  // { label: "At work", value: 11 },
  // { label: "During the day", value: 5 },
  { label: "During the day", value: 18 },
  // { label: "Evening", value: 8 },
  { label: "In the afternoon", value: 19 },
  { label: "In the evening", value: 20 },
  { label: "In the morning", value: 17 },
  // { label: "Morning", value: 6 },
  // { label: "On waking", value: 14 },
  { label: "Upon waking", value: 16 },
  // { label: "With certain movements", value: 15 },
  // { label: "With certain tasks", value: 13 },
  // { label: "With motion", value: 2 },
  { label: "With movement", value: 22 },
];

export const customStyles = {
  option: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 8,
    backgroundColor: "#f5f5f5",
  }),
  singleValue: (provided) => ({
    ...provided,
  }),
};
