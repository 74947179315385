import React, { Component } from "react";
import auth from "./auth";
import { withRouter } from "react-router-dom";
import {
  getWorkFlow,
  createWorkFlow,
  showPage,
  getReviewWorkflow,
} from "../services/api";
import LocalStorageService from "../services/localStorageService";
import { WFE_MODULE, WFE_PROCESS, WFE_STEPORDER } from "../services/constants";
import { getCookie, getKNC } from "../services/cookieService";

class Home extends Component {
  state = {
    completedList: false,
    progressList: "",
    todo: false,
    CurrentForm: "",
    Progress: "",
    Total: "",
    medHistory: false,
    medHistoryCompListSwitch: false,
    loadingCircle: true,
  };
  localStorageService = new LocalStorageService();
  cookie = getCookie();

  componentDidMount() {
    if (this.localStorageService.getRef() === "true") {
      window.location.reload();
      this.localStorageService.clearRef();
    }

    if (this.cookie && getKNC(this.cookie.access_token)) {
      this.props.switchfunc();
    }
    this.props.stepReset();

    if (!this.cookie) {
      auth.logout(() => {
        this.props.history.push("/");
      });
      return;
    }
    if (this.props.history?.location?.state?.workflow["parentEntityID"] > 0) {
      this.reviewWorkflow();
    } else {
      this.getWorkflow();
    }
  }
  cookie = getCookie();
  logout = () => {
    this.props.logout();
  };
  reviewWorkflow = () => {
    getReviewWorkflow({
      value: getKNC(this.cookie.access_token),
      entityID: this.props.history?.location?.state?.workflow["parentEntityID"],
      entityType:
        this.props.history?.location?.state?.workflow["parentEntityType"],
    })
      .then((response) => {
        const workflowList = response.data;
        const progressList = workflowList.filter((workflow) => {
          return workflow.dateCompleted !== null;
        });
        const currentList = workflowList.filter((workflow) => {
          return workflow.dateCompleted === null;
        });
        console.log(progressList);
        console.log(currentList);
        if (currentList.length !== 0) {
          this.setState({
            CurrentForm: currentList[0], //first item in the response bag, as only the first item needs to be displayed
            todo: true,
            loadingCircle: false,
          });
        }

        if (progressList.length !== 0) {
          this.setState({
            progressList: progressList,
            completedList: true,
            loadingCircle: false,
          });

          progressList.forEach((element) => {
            if (
              [
                "Core Medical History",
                "Psychological Medical History",
                "Red Flag Medical History",
                "Family History Medical History",
              ].indexOf(element["module"]) > -1
            ) {
              this.setState({
                medHistory: true,
              });
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loadingCircle: false });
      });
  };
  getWorkflow = () => {
    getWorkFlow({ value: getKNC(this.cookie.access_token) })
      .then((response) => {
        /*  if (
          response.data[0][1].length > 1 &&
          response.data[0][1].map(function (x) {
            return x[6] != null;
          })
        ) */
        const workflowList = response.data;
        const progressList = workflowList.filter((workflow) => {
          return workflow.dateCompleted !== null;
        });
        const currentList = workflowList.filter((workflow) => {
          return workflow.dateCompleted === null;
        });
        console.log(progressList);
        console.log(currentList);
        if (currentList.length !== 0) {
          this.setState({
            CurrentForm: currentList[0], //first item in the response bag, as only the first item needs to be displayed
            todo: true,
            loadingCircle: false,
          });
        }

        if (progressList.length !== 0) {
          this.setState({
            progressList: progressList,
            completedList: true,
            loadingCircle: false,
          });

          progressList.forEach((element) => {
            if (
              [
                "Core Medical History",
                "Psychological Medical History",
                "Red Flag Medical History",
                "Family History Medical History",
              ].indexOf(element["module"]) > -1
            ) {
              this.setState({
                medHistory: true,
              });
            }
          });
        }

        // if (response.data[2]) {
        //   console.log(response.data[2]);

        //   this.setState({
        //     CurrentForm: response.data[2][0]["value"],
        //     loadingCircle: false,
        //   });
        // }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loadingCircle: false });
      });
  };
  continue = (e) => {
    this.localStorageService.setWorkFlowId(e[0]);
    if (e["wfeModuleID"] === WFE_MODULE.PERSONAL_DETAILS) {
      auth.login(() => {
        this.props.history.push({
          pathname: "/patientDetails",
          state: { workflow: e },
        });
      });
    } else if (e["wfeModuleID"] === WFE_MODULE.INJURY_DETAILS) {
      auth.login(() => {
        this.props.history.push({
          pathname: "/painIndicator",
          state: { workflow: e },
        });
      });
    } else if (
      e["wfeModuleID"] === WFE_MODULE.CORE_MEDICAL_HISTORY ||
      e["wfeModuleID"] === WFE_MODULE.LIFESTYLE_MEDICAL_HISTORY ||
      e["wfeModuleID"] === WFE_MODULE.RED_FLAG_MEDICAL_HISTORY ||
      e["wfeModuleID"] === WFE_MODULE.FAMILY_HISTORY_MEDICAL_HISTORY ||
      e["wfeModuleID"] === WFE_MODULE.INDUSTRY_SPECIFIC_SCREEN
    ) {
      auth.login(() => {
        this.props.history.push({
          pathname: "/coreMedicalHistory",
          state: { workflow: e },
        });
      });
    } else if (e["wfeModuleID"] === WFE_MODULE.JOB_HISTORY_DETAILS) {
      auth.login(() => {
        this.props.history.push({
          pathname: "/pesJob",
          state: { workflow: e },
        });
      });
    } else if (e["wfeModuleID"] === WFE_MODULE.FOOD_HANDLER) {
      auth.login(() => {
        this.props.history.push({
          pathname: "/pesFoodQ",
          state: { workflow: e },
        });
      });
    } else return null;
  };

  // todoList = (e) => {
  //   return (
  //     <div>
  //       <div className="todoList">
  //         <label style={{ float: "left" }} className="abc">
  //           {e["patientPortalDisplay"]}
  //         </label>
  //         <button
  //           style={{ float: "right", marginTop: "4px", minWidth: "88px" }}
  //           className="btn btn-primary modal-btn"
  //           data-modal-id="sampleModal"
  //           onClick={() => this.continue(e)}
  //         >
  //           Begin
  //         </button>
  //       </div>
  //     </div>
  //   );
  // };

  // viewMedHistory = (val) => {
  //   return (
  //     <div>
  //       <div className="todoList">
  //         <label style={{ float: "left" }} className="abc">
  //           {val}
  //         </label>

  //         <button
  //           style={{ float: "right", marginTop: "4px" }}
  //           className="btn btn-outline-primary modal-btn"
  //           data-modal-id="sampleModal"
  //           onClick={() => this.setState({ completedList: false })}
  //         >
  //           Review
  //         </button>
  //       </div>
  //     </div>
  //   );
  // };

  completedList = (e) => {
    return (
      <div key={e["wfeWorkflowID"]}>
        <div className="todoList">
          <label className="abc">{e["patientPortalDisplay"]}</label>

          <button
            // style={{ float: "right", marginTop: "4px" }}
            className="btn btn-list modal-btn"
            data-modal-id="sampleModal"
            onClick={() => this.continue(e)}
          >
            Review
          </button>
        </div>
      </div>
    );
  };

  begin = () => {
    this.setState({
      loadingCircle: true,
    });
    let body = {
      UUID: getKNC(this.cookie.access_token),
      DateCompleted: new Date(),
    };
    createWorkFlow(body)
      .then((response) => {
        this.getWorkflow();
      })
      .catch((error) => {
        this.setState({
          loadingCircle: false,
        });
        console.log(error);
      });
  };

  displayMessage = () => {
    if (this.state.CurrentForm["wfeModuleID"] === WFE_MODULE.PERSONAL_DETAILS) {
      return (
        <label className="abc">
          To ensure we can provide you with the best quality care, please answer
          the following questions.
        </label>
      );
    } else if (
      this.state.CurrentForm["wfeModuleID"] === WFE_MODULE.INJURY_DETAILS &&
      this.state.CurrentForm["wfeProcessID"] === 6
    ) {
      return (
        <label className="abc">
          Welcome to Your Health Manager. To get you ready for your
          appointment,we need to know about your current injury or area of
          concern. This should take around two minutes to complete. Please also
          take the time to review and update your personal details.
        </label>
      );
    } else {
      return (
        <label className="abc">
          Thank you for completing your details. You will be able to discuss
          your concerns during your appointment. We look forward to helping you
          soon.
        </label>
      );
    }
  };
  render() {
    const { loadingCircle } = this.props;

    return (
      <div>
        <div id="MainDiv">
          {this.state.loadingCircle === true ? loadingCircle : null}
          <div>
            <div className="row has-form">
              <div className="title">
                <h1>Your Health Manager</h1>
              </div>
            </div>
          </div>
          <div className="row has-form">{this.displayMessage()}</div>

          <div>
            <div className="row has-form">
              {/* {this.state.todo ? null : (
                <div style={{ marginBottom: "65px" }}>
                  <h4 style={{ fontWeight: "700" }}>
                    Have a new injury you need to see us about?
                  </h4>

                  <br></br>
                  <label className="abc" style={{ float: "left" }}>
                    New Injury
                  </label>
                  <button
                    id="myBtn"
                    style={{
                      float: "right",
                      marginTop: "4px",
                      minWidth: "88px",
                    }}
                    className="btn btn-primary modal-btn"
                    data-modal-id="sampleModal"
                    onClick={() => this.begin()}
                  >
                    Begin
                  </button>
                </div>
              )} */}

              <div>
                {this.state.todo ? (
                  <div>{showPage(this.state.CurrentForm, this.props)}</div>
                ) : null}

                {!this.state.todo && this.state.completedList ? (
                  <div>
                    {/* {Array.from(this.state.progressList, (e, i) => {
                      if (
                        e[2] === WFE_MODULE.PERSONAL_DETAILS ||
                        e[2] === WFE_MODULE.LIFESTYLE_MEDICAL_HISTORY ||
                        e[2] === "Musculoskeletal Screen Medical History" ||
                        e[2] === WFE_MODULE.INJURY_DETAILS
                      ) {
                        return this.completedList(e);
                      } else if (e[12] === "Current Employment Details") {
                        return null;
                      }
                    })} */}
                    {Array.from(this.state.progressList, (e, i) => {
                      if (e["module"] === "Current Employment Details") {
                        return null;
                      } else {
                        return this.completedList(e);
                      }
                    })}
                    {/* {this.state.medHistory
                      ? this.viewMedHistory("Medical History")
                      : null} */}
                    <div className="btn-block prev-back-btn">
                      {/* <button className="btn btn-outline-primary" onClick={this.goBack}>
              Back
            </button> */}
                      <button
                        className="btn btn-primary modal-btn"
                        data-modal-id="sampleModal"
                        onClick={this.logout}
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Home);
