import React, { Component } from "react";
import "react-dropdown/style.css";
import auth from "../auth";
import Select from "react-select";
import {
  getNextWorkFlowDetails,
  saveContactDetails,
  showPage,
} from "../../services/api";
import { getCookie, getKNC } from "../../services/cookieService";
import { withRouter } from "react-router-dom";
import { customStyles, WFE_PROCESS } from "../../services/constants";
import ProgressBar from "react-bootstrap/ProgressBar";
const stateOptions = [
  { label: "ACT", value: 6 },
  { label: "NSW", value: 3 },
  { label: "NT", value: 4 },
  { label: "QLD", value: 1 },
  { label: "SA", value: 7 },
  { label: "TAS", value: 5 },
  { label: "VIC", value: 2 },
  { label: "WA", value: 8 },
];

class ContactDetails extends Component {
  cookie = getCookie();
  state = { submit: false, Countryoptions: [], loadingCircle: false };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  changeLoadingCircle = (flag) => {
    this.setState({ loadingCircle: flag });
    return true;
  };
  continue = (e) => {
    const isValid = this.validate();

    if (
      isValid &&
      this.props.state.addressLine1isValid &&
      this.props.state.addressLine2isValid &&
      this.props.state.suburbisValid &&
      this.props.state.stateNameisValid &&
      this.props.state.postCodeisValid
    ) {
      this.changeLoadingCircle(true);
      const schema = {
        schema: {
          Line1: this.props.state.addressLine1,
          Line2: this.props.state.addressLine2,
          Suburb: this.props.state.suburb,
          StateID: this.props.state.stateCode,
          PostCode: this.props.state.postCode,
          Mobile: this.props.state.mobileNumber,
          AlternativePhone: this.props.state.alternativePhone,
          AddressID: this.props.state.addressID,
          CountryID: 8,
          WorkflowID:
            this.props.workflow["dateCompleted"] === null
              ? this.props.workflow["wfeWorkflowID"]
              : -1,
          UUID: this.props.state.KNC
            ? this.props.state.KNC
            : getKNC(this.cookie.access_token),
        },
      };
      // implementing save after each page
      saveContactDetails(schema.schema)
        .then(() => {
          e.preventDefault();
          /*
          220531 VG: since, the state value has dateCompleted field which is still null, it can be passed to the next page to get the next workflow details.
          */
          if (this.props.workflow["dateCompleted"] === null) {
            showPage(this.props.state.nextWorkflow, this.props);
          } else {
            showPage(this.props.state.workflow, this.props);
          }
          this.changeLoadingCircle(false);
        })
        .catch((error) => {
          window.alert(error);
          this.changeLoadingCircle(false);
        });
    } else this.setState({ submit: true });
  };

  validate = () => {
    let nameError = "";

    const val = this.props.state;
    if (
      val.addressLine1 === "" ||
      val.suburb === "" ||
      val.stateName === "" ||
      val.postCode === "" ||
      val.mobileNumber === ""
    ) {
      nameError = "This field is required";
    }
    if (nameError) {
      this.setState({ nameError });
      return false;
    } else {
      this.setState({
        nameError: "",
      });
      return true;
    }
  };

  saveCountry = (data) => {
    this.setState({ Countryoptions: data });
    this.props.countryCodef(data);

    console.log(this.Countryoptions);
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.stateCodef(stateOptions);
  }

  render() {
    const { handleChange, state, loadingCircle } = this.props;
    return (
      <div id="MainDiv">
        {this.state.loadingCircle === true ? loadingCircle : null}
        <div className="has-form">
          <div className="title">
            {this.props.state.workflow["dateCompleted"] === null && (
              <div className="div-progress-bar">
                <p>{this.props.state.workflow.statusDisplay}</p>
                <ProgressBar
                  animated
                  now={this.props.state.workflow.statusPercentage}
                />
              </div>
            )}
            <h1>Personal Details</h1>
          </div>
        </div>
        <div className="has-form">
          <div className="row">
            <div className="form-group">
              <label className="abc">Address line 1</label>
              <input
                type="text"
                className="form-control"
                id="addressLine1"
                value={state.addressLine1}
                onChange={handleChange("addressLine1")}
              />

              <div className="errorMessage">{state.addressLine1Error}</div>
              <div className="errorMessage">
                {state.addressLine1Error
                  ? null
                  : this.props.state.addressLine1 === "" &&
                    this.state.nameError}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label className="abc">
                Address line 2<span className="optional">Optional</span>{" "}
              </label>
              <input
                type="text"
                className="form-control"
                id="addressLine2"
                value={state.addressLine2}
                onChange={handleChange("addressLine2")}
              />
              <div className="errorMessage">{state.addressLine2Error}</div>
            </div>
          </div>

          <div className="row">
            <div className="form-group">
              <label className="abc">Suburb</label>
              <input
                type="text"
                className="form-control"
                id="suburb"
                value={state.suburb}
                onChange={handleChange("suburb")}
              />
              <div className="errorMessage">{state.suburbError}</div>
              <div className="errorMessage">
                {state.suburbError
                  ? null
                  : this.props.state.suburb === "" && this.state.nameError}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group">
              <label className="abc">State</label>

              <Select
                options={stateOptions}
                // theme={(theme) => ({
                //   ...theme,
                //   borderRadius: 8,
                // })}
                styles={customStyles}
                isSearchable
                menuPlacement="top"
                onChange={handleChange("stateName")}
                value={state.stateName}
              ></Select>

              <div className="errorMessage">{state.stateNameError}</div>
              <div className="errorMessage">
                {state.stateNameError
                  ? null
                  : this.props.state.stateName === "" && this.state.nameError}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group">
              <label className="abc">Postcode</label>
              <input
                type="number"
                id="postCode"
                className="form-control"
                value={state.postCode}
                onChange={handleChange("postCode")}
              ></input>

              <div className="errorMessage">{state.postCodeError}</div>
              <div className="errorMessage">
                {state.postCodeError
                  ? null
                  : this.props.state.postCode === "" && this.state.nameError}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label className="abc">Mobile</label>
              <input
                className="form-control"
                id="mobileNumber"
                placeholder="e.g. 0400123123"
                type="number"
                value={state.mobileNumber}
                onChange={handleChange("mobileNumber")}
              />

              <div className="errorMessage">{state.mobileNumberError}</div>
              <div className="errorMessage">
                {state.mobileNumberError
                  ? null
                  : this.props.state.mobileNumber === "" &&
                    this.state.nameError}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label className="abc">
                Alternative Phone<span className="optional">Optional</span>
              </label>
              <input
                className="form-control"
                id="alternativePhone"
                type="number"
                value={state.alternativePhone}
                onChange={handleChange("alternativePhone")}
              />

              <div className="errorMessage">{state.alternativePhoneError}</div>
            </div>
          </div>

          <div className="btn-block prev-back-btn">
            {/* <button className="btn btn-outline-primary" onClick={this.back}>
                Back
              </button> */}
            <button
              className="btn btn-primary modal-btn"
              data-modal-id="sampleModal"
              onClick={this.continue}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ContactDetails);

/* 
220302 VG: v2 code
<p> Step 2 of 3 </p> 
*/
