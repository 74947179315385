import React, { Component } from "react";
import auth from "./auth";
import { withRouter } from "react-router-dom";
import { getWorkFlow } from "../services/api";
import { getCookie, getKNC } from "../services/cookieService";

class FormProgress extends Component {
  state = {
    CurrentForm: "",
    NextForm: "",
    Progress: "",
    Total: "",
  };

  componentDidMount() {
    const cookie = getCookie();

    getWorkFlow({ value: getKNC(cookie.access_token) })
      .then((response) => {
        this.setState({
          CurrentForm: response.data[0].CurrentForm,
          NextForm: response.data[0].NextForm,
          Progress: response.data[1].Progress,
          Total: response.data[1].Total,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  continue = (e) => {
    if (e === "Core Medical History") {
      auth.login(() => {
        this.props.history.push("/CoreMedicalHistory");
      });
    } else if (e === "Lifestyle Medical History") {
      auth.login(() => {
        this.props.history.push("/CoreLifeStyleModule");
      });
    } else if ((e === "Personal Details") | "Current Employment Details") {
      auth.login(() => {
        this.props.history.push("/patientDetails");
      });
    } else if (e === "Psychological Medical History") {
      auth.login(() => {
        this.props.history.push("/CorePsychologicalModule");
      });
    } else if (e === "Musculoskeletal Screen Medical History") {
      auth.login(() => {
        this.props.history.push("/MusculoskeletonModule");
      });
    } else if (e === "Red Flag Medical History") {
      auth.login(() => {
        this.props.history.push("/RedFlagModule");
      });
    } else if (e === "Family History Medical History") {
      auth.login(() => {
        this.props.history.push("/FamilyHistoryModule");
      });
    } else if (e === "Manual Handling Screen") {
      auth.login(() => {
        this.props.history.push("/ManualHandling");
      });
    } else if (e === "Industry Specific Screen") {
      auth.login(() => {
        this.props.history.push("/IndustrySpecificModule");
      });
    } else return null;
  };

  todoList = () => {
    return (
      <div>
        <div className="todoList">
          <h4 style={{ float: "left" }}>{this.state.CurrentForm}</h4>
          <button
            style={{ float: "right", "margin-top": "4px;" }}
            className="btn btn-primary modal-btn"
            data-modal-id="sampleModal"
            onClick={() => this.continue(this.state.CurrentForm)}
          >
            Begin
          </button>
        </div>
        <br></br>
      </div>
    );
  };

  render() {
    return (
      <div className="cont" id="MainDiv">
        <div>
          <div className="page-title lg">
            <div className="title">
              <h1>Your Health Manager</h1>
            </div>
          </div>
          <div>
            <h4 style={{ fontWeight: "700" }}>To Do</h4>
            <br></br>
            {this.todoList()}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(FormProgress);
