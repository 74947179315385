/*
this file exposes different end points required for the patient portal

accepted syntax: export const NAME = "end point"
*/

export const PERSONAL_DETAILS = "personaldetails";
export const CONTACT_DETAILS = "contactdetails";
export const REGISTER = "api/register";
export const WORK_FLOW_NEW_REG = "api/workflowNewreg";
export const USER_AUTH = "/api/userAuth";
export const SIGN_IN = "api/signin";
export const WORKFLOW = "api/workflow";
export const FORGOT_PASSWORD = "api/forgotpassword";
export const CONFIRM_PASSWORD = "api/confirmpassword";
export const ETHNICITY = "v1/personaldetails/Ethnicity";
export const COUNTRY = "v1/personaldetails/Country";
export const POBdetails = "api/POBdetails";
export const SAVE_WORKFLOW = "api/saveWorkflow";
export const SIGNUP = "personaldetails/signup";
// export const NAME_DETAILS = "v1/personaldetails/namedetails";
// export const ADDRESS_DETAILS = "v1/personaldetails/saveaddress";
export const ATTRIBUTE_DETAILS = "v1/personaldetails/saveattribute";
export const NEXT_WORKFLOW = "api/workflow/next";
export const JOB_DETAILS = "jobdetails";
export const MED_DETAILS = "api/medlist";
export const SUPP_DETAILS = "api/supplist";
export const MEDHISTORY_DETAILS = "api/medhistorydetails";
export const CORE_MEDHISTORY_DETAILS = "api/coremedicalhistory";
export const FAMILY_MEDHISTORY_DETAILS = "api/familymedicalhistory";
export const LIFESTYLE_MEDHISTORY_DETAILS = "api/corelifestylehistory";
export const ADDITIONAL_MEDHISTORY_DETAILS = "api/additionalmedicalhistory";
export const WORK_RELATED_MEDHISTORY_DETAILS = "api/workrelatedhistory";
export const MEDLIST_INSERT = "api/med_list_insert";
export const PES_DETAILS = "api/pesdetails";
export const PES_MEDHISTORY_DETAILS = "api/pesmedhistory";
export const PES_FOOD_QUESTIONNAIRE_DETAILS = "api/pesfoodq";
export const POBSymptomsdetails = "api/POBdetails/symptoms";
export const RETURN_PATIENT_NEW_INURY = "api/workflow/newinjury";
export const EXISTING_ENTRIES = "api/workflow/entries";
export const REVIEW_WORKFLOW = "api/workflow/review";
export const REVIEW_PERSONAL_DETAILS = "api/workflow/reviewpersonaldetails";
export const INCOMPLETE_WORKFLOW = "api/incompleteworkflow";
export const RETURN_PATIENT_NEW_PES = "api/workflow/newpes";
