import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getCookie, getKNC } from "../services/cookieService";
import {
  createNewInjuryWorkflow,
  getIncompleteWorkFlow,
  reviewePersonalDetails,
  createNewPESWorkflow,
} from "../services/api";
import auth from "./auth";
import LocalStorageService from "../services/localStorageService";
class SplashScreen extends Component {
  localStorageService = new LocalStorageService();
  state = {
    currentList: [],
  };
  cookie = getCookie();
  logout = () => {
    this.props.logout();
  };
  componentDidMount() {
    if (this.localStorageService.getPathName() === "/candidatepes") {
      const schema = {
        schema: {
          UUID: this.props.state.KNC
            ? this.props.state.KNC
            : getKNC(this.cookie.access_token),
        },
      };
      createNewPESWorkflow(schema.schema)
        .then(() => {
          this.localStorageService.clearPathName();
          getIncompleteWorkFlow({ value: getKNC(this.cookie.access_token) })
            .then((response) => {
              const workflowList = response.data;
              const currentList = workflowList.filter((workflow) => {
                return workflow.dateCompleted === null;
              });
              this.setState({
                currentList: currentList,
              });
              console.log(currentList);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getIncompleteWorkFlow({ value: getKNC(this.cookie.access_token) })
        .then((response) => {
          const workflowList = response.data;
          const currentList = workflowList.filter((workflow) => {
            return workflow.dateCompleted === null;
          });
          this.setState({
            currentList: currentList,
          });
          console.log(currentList);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  newInjuryWorkflow = async () => {
    const schema = {
      schema: {
        UUID: this.props.state.KNC
          ? this.props.state.KNC
          : getKNC(this.cookie.access_token),
      },
    };

    await createNewInjuryWorkflow(schema.schema)
      .then(() => {
        auth.login(() => {
          this.props.history.push({
            pathname: "/Home",
          });
        });
      })
      .catch((error) => {});
  };
  getxistingEntries = () => {
    auth.login(() => {
      this.props.history.push({
        pathname: "/reviewscreen",
      });
    });
  };
  reviewePersonalDetails = () => {
    const schema = {
      schema: {
        UUID: this.props.state.KNC
          ? this.props.state.KNC
          : getKNC(this.cookie.access_token),
      },
    };
    reviewePersonalDetails(schema.schema).then(() => {
      auth.login(() => {
        this.props.history.push({
          pathname: "/Home",
        });
      });
    });
  };
  display = () => {
    if (this.state.currentList.length === 0) {
      return (
        <div>
          <div id="MainDiv">
            <div>
              <div className="row has-form">
                <div className="title">
                  <h1>Welcome back</h1>
                </div>
              </div>
            </div>

            <div>
              <div className="row has-form">
                <div>
                  <div className="todoList">
                    <label className="abc">Submit a New Injury</label>
                    <button
                      className="btn btn-list modal-btn"
                      data-modal-id="sampleModal"
                      onClick={this.newInjuryWorkflow}
                    >
                      Begin
                    </button>
                  </div>
                  <div className="todoList">
                    <label className="abc">Review an Existing Entry</label>
                    <button
                      className="btn btn-list modal-btn"
                      data-modal-id="sampleModal"
                      onClick={this.getxistingEntries}
                    >
                      Review
                    </button>
                  </div>
                  <div className="todoList">
                    <label className="abc">Change Personal Details</label>
                    <button
                      className="btn btn-list modal-btn"
                      data-modal-id="sampleModal"
                      onClick={this.reviewePersonalDetails}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="btn-block prev-back-btn">
                    <button
                      className="btn btn-primary modal-btn"
                      data-modal-id="sampleModal"
                      onClick={this.logout}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      auth.login(() => {
        this.props.history.push({
          pathname: "/Home",
        });
      });
    }
  };
  render() {
    return <div>{this.display()}</div>;
  }
}
export default withRouter(SplashScreen);
