import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getCookie, getKNC } from "../services/cookieService";
import { getExistingEntries } from "../services/api";
import auth from "./auth";
class ReviewScreen extends Component {
  state = {
    entries: [],
  };
  cookie = getCookie();
  logout = () => {
    this.props.logout();
  };
  componentDidMount() {
    getExistingEntries({ value: getKNC(this.cookie.access_token) })
      .then(({ data }) => {
        this.setState({
          entries: data,
        });
      })
      .catch((error) => {});
  }
  reviewWorkflow = (e) => {
    auth.login(() => {
      this.props.history.push({
        pathname: "/Home",
        state: { workflow: e },
      });
    });
  };
  display = () => {
    return (
      <div>
        <div id="MainDiv">
          <div>
            <div className="row has-form">
              <div className="title">
                <h1>Current Entries</h1>
              </div>
            </div>
          </div>
          <div className="review-screen-container">
            <div id="reviewSummaryTable">
              <div>
                <div className="reviewFlexRow">
                  <div class="mobile-review-screen">Start</div>
                  <div class="mobile-review-screen">Type</div>
                  <div>Body Region</div>
                  <div>Status</div>
                </div>
                {this.state.entries.map((entry, i) => {
                  return (
                    <div key={i} className="reviewFlexRow">
                      <div class="mobile-review-screen">{entry.createDate}</div>
                      <div class="mobile-review-screen">
                        {entry.parentEntityType === 53 ? "Injury" : "PES"}
                      </div>
                      <div>{entry.description}</div>
                      <div>
                        <button
                          className="btn btn-list modal-btn"
                          data-modal-id="sampleModal"
                          onClick={() => this.reviewWorkflow(entry)}
                          disabled={entry.editable === 0 ? "disabled" : ""}
                        >
                          {entry.editable === 1 ? "Edit" : "Locked"}
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* <div className="row has-form">
              <div className="todoList">

                <label className="abc">Start</label>
                <label className="abc">Type</label>
                <label className="abc">Body Region</label>
                <label className="abc">Status</label>
              </div>
              {this.state.entries.map((entry, i) => {
                return (
                  <div key={i} className="todoList">
                    <label className="abc">{entry.createDate}</label>
                    <label className="abc">
                      {entry.parentEntityType === 53 ? "Injury" : "PES"}
                    </label>
                    <label className="abc">{entry.description}</label>
                    <button
                      className="btn btn-list modal-btn"
                      data-modal-id="sampleModal"
                      onClick={() => this.reviewWorkflow(entry)}
                      disabled={entry.editable === 0 ? "disabled" : ""}
                    >
                      {entry.editable === 1 ? "Edit" : "Locked"}
                    </button>
                  </div>
                );
              })}
            </div> */}
          </div>
        </div>
      </div>
    );
  };
  render() {
    return <div>{this.display()}</div>;
  }
}
export default withRouter(ReviewScreen);
