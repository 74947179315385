import React, { Component, Suspense } from "react";
import "../App.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";

import { ProtectedRoute } from "./protected.route";

import "react-dropdown/style.css";
import PersonalDetails from "./PersonalDetailsModule/PersonalDetails";
import JobDetails from "./PersonalDetailsModule/JobDetails";
import ContactDetails from "./PersonalDetailsModule/ContactDetails";
//import Step4 from "./Step4";
import Loginpage from "./loginpage";
import Brilliant from "./brilliantpage";
import History from "../patientHistory";
import Home from "./Home";

import FormProgress from "./FormProgress";

import { Ouroboro } from "react-spinners-css";
import {
  DEFAULT_DATE,
  ETHNICITY_OPTIONS,
  STATE_OPTIONS,
} from "../services/constants";
import PESJobDetails from "./PES/PESJobDetails";
import FoodHandlersQuestionnaire from "./PES/FoodHandlersQuestionnaire";
import SplashScreen from "./SplashScreen";
import ReviewScreen from "./ReviewScreen";
const PersonalDetailsMain = React.lazy(() =>
  import("./PersonalDetailsModule/PersonalDetailsMain")
);

const Signup = React.lazy(() => import("./signuppage"));
const ForgotPassword = React.lazy(() => import("./ForgotPassword"));

const BodyImageMain = React.lazy(() =>
  import("../components/InjuryDetailsModule/BodyImageMain")
);
const MedicalHistoryMain = React.lazy(() =>
  import("../components/PatientIntakeModules/MedicalHistoryMain")
);
const CorePsychologicalModule = React.lazy(() =>
  import("../components/PatientIntakeModules/CorePsychologicalModule")
);
const RedFlagModule = React.lazy(() =>
  import("../components/PatientIntakeModules/RedFlagModule")
);
const CoreLifeStyleModule = React.lazy(() =>
  import("../components/PatientIntakeModules/CoreLifeStyleModule")
);
const ManualHandling = React.lazy(() =>
  import("../components/PatientIntakeModules/ManualHandling")
);
const IndustrySpecificModule = React.lazy(() =>
  import("../components/PatientIntakeModules/IndustrySpecificModule")
);
const AdditionalMedicalHistory = React.lazy(() =>
  import("./PatientIntakeModules/AdditionalMedicalHistory")
);
const FamilyHistoryModule = React.lazy(() =>
  import("../components/PatientIntakeModules/FamilyHistoryModule")
);
const MusculoskeletonModule = React.lazy(() =>
  import("../components/PatientIntakeModules/Musculoskeleton_Module")
);
const OreboModule = React.lazy(() =>
  import("../components/PatientIntakeModules/OreboModule")
);
// prom
const NDSModule = React.lazy(() =>
  import("../components/PROMModules/NDSModule")
);
const QuebecModule = React.lazy(() =>
  import("../components/PROMModules/QuebecModule")
);
const LEFSModule = React.lazy(() =>
  import("../components/PROMModules/LEFSModule")
);
const PSSModule = React.lazy(() =>
  import("../components/PROMModules/PSSModule")
);
const FABQMain = React.lazy(() =>
  import("../components/PROMModules/FABQModule/FABQMain")
);
const FOSQModule = React.lazy(() =>
  import("../components/PROMModules/FOSQModule")
);
const PainScaleModule = React.lazy(() =>
  import("../components/PROMModules/PainScaleModule")
);
const DASSModule = React.lazy(() =>
  import("../components/PROMModules/DASSModule")
);
const DASHModule = React.lazy(() =>
  import("../components/PROMModules/DASHModule")
);

const IsLoading = () => (
  <div>
    <Ouroboro
      style={{
        position: "absolute",
        // marginLeft: "275px",
        // marginTop: "400px",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        margin: "auto",
        zIndex: "1",
      }}
      color={"var(--purple)"}
      size={400}
    />
  </div>
);

class Main extends Component {
  //220203 VG: try to make one state and remove resetState
  state = {
    step: 1,
    //step1
    titleOpt: "",
    titleOptError: "",
    givenName: "",
    givenNameError: "",
    givenNameisValid: true,
    surName: "",
    surNameError: "",
    surNameisValid: true,
    middleName: "",
    middleNameisValid: true,
    DateofB: "",
    DateofBError: "",

    DateofBisValid: true,
    gender: "",
    genderError: "",
    mobileNumber: "",
    alternativePhone: "",
    alternativePhoneError: "",
    alternativePhoneisValid: true,
    mobileNumberError: "",

    mobileNumberisValid: true,
    email: "",
    emailError: "",
    culturalGroup: "",
    //step2
    // CurrentPosition: "",
    PreviousJob: "",
    previousJobError: "",
    previousJobisValid: true,
    // EmpStDate: "",
    // EmpStDateError: "",
    // EmpStDateisValid: true,
    LastEmpDate: "",
    LastEmpDateError: "",
    LastEmpDateisValid: true,
    //Department: "",
    Employer: "",
    employerError: "",
    employerisValid: true,
    LengthYears: "",
    LengthYearsError: "",
    LengthYearsisValid: true,
    LengthMonths: "",
    LengthMonthsError: "",
    LengthMonthsisValid: true,
    //LineTask: "",
    CompClaim: "",
    CompClaimDetails: "",
    CompClaimDetailsError: "",
    CompClaimDetailsisValid: true,
    //step3
    addressLine1: "",
    addressLine1Error: "",
    addressLine1isValid: true,
    addressLine2: "",
    addressLine2Error: "",
    addressLine2isValid: true,
    suburb: "",
    suburbError: "",
    suburbisValid: true,
    stateName: "",
    stateNameError: "",
    stateNameisValid: true,
    postCode: "",
    postCodeError: "",
    postCodeisValid: true,
    country: "",
    countryError: "",
    countryisValid: true,
    //step4
    familyDoctor: "",
    lastVisit: "",
    reasonOfVisit: "",
    height: "",
    heightError: "",
    heightisValid: true,
    weight: "",
    weightError: "",
    weightisValid: true,
    handedness: "",
    //
    errormsg: "",
    KNC: "",
    ethnicityCode: "",
    ethnicityoptions: ETHNICITY_OPTIONS,
    Countryoptions: [],
    countryCode: "",
    stateOpts: STATE_OPTIONS,
    stateCode: "",
    stepActivate: false,
    mode: "",
  };
  resetForm = () => {
    this.setState({
      step: 1,
      //step1
      titleOpt: "",
      titleOptError: "",
      givenName: "",
      givenNameError: "",
      givenNameisValid: true,
      surName: "",
      surNameError: "",
      surNameisValid: true,
      middleName: "",
      middleNameisValid: true,
      DateofB: "",
      DateofBError: "",

      DateofBisValid: true,
      gender: "",
      genderError: "",
      mobileNumber: "",
      alternativePhone: "",
      alternativePhoneError: "",
      alternativePhoneisValid: true,
      mobileNumberError: "",

      mobileNumberisValid: true,
      email: "",
      emailError: "",
      culturalGroup: "",
      //step2
      // CurrentPosition: "",
      PreviousJob: "",
      // EmpStDate: "",
      // EmpStDateError: "",
      // EmpStDateisValid: true,
      LastEmpDate: "",
      LastEmpDateError: "",
      LastEmpDateisValid: true,
      //Department: "",
      Employer: "",
      LengthYears: "",
      LengthYearsError: "",
      LengthYearsisValid: true,
      LengthMonths: "",
      LengthMonthsError: "",
      LengthMonthsisValid: true,
      //LineTask: "",
      //CompClaim: "",
      CompClaimDetails: "",
      CompClaimDetailsError: "",
      CompClaimDetailsisValid: true,
      //step3
      addressLine1: "",
      addressLine1Error: "",
      addressLine1isValid: true,
      addressLine2: "",
      addressLine2Error: "",
      addressLine2isValid: true,
      suburb: "",
      suburbError: "",
      suburbisValid: true,
      stateName: "",
      stateNameError: "",
      stateNameisValid: true,
      postCode: "",
      postCodeError: "",
      postCodeisValid: true,
      country: "",
      countryError: "",
      countryisValid: true,
      //step4
      familyDoctor: "",
      lastVisit: "",
      reasonOfVisit: "",
      height: "",
      heightError: "",
      heightisValid: true,
      weight: "",
      weightError: "",
      weightisValid: true,
      handedness: "",
      //
      errormsg: "",
      KNC: "",
      ethnicityCode: "",
      ethnicityoptions: ETHNICITY_OPTIONS,
      Countryoptions: [],
      countryCode: "",
      stateOpts: STATE_OPTIONS,
      stateCode: "",
      stepActivate: false,
      mode: "",
    });
  };

  uuidset = (e) => {
    this.setState({ UUID: e });
  };

  ethnicityCodef = (e) => {
    this.setState({ ethnicityoptions: e });
  };

  countryCodef = (e) => {
    this.setState({ Countryoptions: e });
  };

  stateCodef = (e) => {
    this.setState({ stateOpts: e });
  };

  stepReset = () => {
    // logic for taking patients to the page that is yet to be filled by bypassing filled details
    if (this.state.height > 0) {
      this.setState({ step: 1 });
    } else if (this.state.suburb.length > 0) {
      this.setState({ step: 3 });
    } else if (this.state.titleOpt.length > 0) {
      this.setState({ step: 2 });
    } else {
      this.setState({ step: 1 });
    }
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  dateFormatter = (date) => {
    var d = new Date(new Date(Date.parse(date)).toDateString()),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }

    return [year, month, day].join("-");
  };

  getdetails = (patient, address, employment) => {
    if (patient.length) {
      let sex = patient[0].gender.replace(/\s+/g, "");
      let temp;
      if (sex === "M") {
        temp = "M";
      } else if (sex === "F") {
        temp = "F";
      } else if (sex === "U") {
        temp = "U";
      } else {
        temp = "";
      }

      let code = this.state.ethnicityoptions.findIndex(function (item, i) {
        return item.value === patient[0].ethnicityID;
      });

      this.setState({
        titleOpt: patient[0].title ? patient[0].title : this.state.titleOpt,
        givenName: patient[0].firstName,
        surName: patient[0].lastName,
        middleName: patient[0].middleNames,

        DateofB:
          new Date(patient[0].dateOfBirth).getFullYear() ===
          new Date(DEFAULT_DATE).getFullYear()
            ? null
            : this.dateFormatter(patient[0].dateOfBirth),

        gender: temp ? temp : this.state.gender,
        // mobileNumber:
        //   "0" + patient[0].mobile
        //     ? "0" + patient[0].mobile
        //     : this.state.mobileNumber,
        // alternativePhone:
        //   "0" + patient[0].alternativePhone
        //     ? "0" + patient[0].alternativePhone
        //     : this.state.alternativePhone,
        email: patient[0].email ? patient[0].email : this.state.email,
        culturalGroup: code
          ? this.state.ethnicityoptions[code]
          : this.state.culturalGroup,
      });
      this.setState({
        ethnicityCode: this.state.ethnicityoptions[code].value,
      });

      this.setState({
        familyDoctor: patient[0].familyDoctor
          ? patient[0].familyDoctor
          : this.state.familyDoctor,
        lastVisit: patient[0].lastVisit
          ? this.dateFormatter(patient[0].lastVisit)
          : this.state.lastVisit,
        reasonOfVisit: patient[0].whyLastVisit
          ? patient[0].whyLastVisit
          : this.state.reasonOfVisit,
        height: patient[0].height ? patient[0].height : this.state.height,
        weight: patient[0].weightKg ? patient[0].weightKg : this.state.weight,
        handedness: patient[0].handedness
          ? patient[0].handedness
          : this.state.handedness,
      });
    }

    if (address.length) {
      let statecode = [
        { label: "ACT", value: 6 },
        { label: "NSW", value: 3 },
        { label: "NT", value: 4 },
        { label: "QLD", value: 1 },
        { label: "SA", value: 7 },
        { label: "TAS", value: 5 },
        { label: "VIC", value: 2 },
        { label: "WA", value: 8 },
      ].findIndex(function (item, i) {
        return item.value === address[0].stateID;
      });

      this.setState({
        stateCode: this.state.stateOpts[statecode].value,
      });

      this.setState({
        // //step3
        addressLine1: address[0].line1
          ? address[0].line1
          : this.state.addressLine1,
        addressLine2: address[0].line2
          ? address[0].line2
          : this.state.addressLine2,
        suburb: address[0].suburb ? address[0].suburb : this.state.suburb,
        stateName:
          statecode === 0 || statecode
            ? this.state.stateOpts[statecode]
            : this.state.stateName,
        postCode: address[0].postCode
          ? address[0].postCode
          : this.state.postCode,
      });
    }
  };

  validator = (e) => {
    var name = /^[a-zA-Z '-]*$/;
    var phno = /^\d{10}$/;
    if (e.target.id === "givenName") {
      if (!e.target.value.match(name)) {
        this.setState({
          givenNameError: "No numbers or special characters allowed",
          givenNameisValid: false,
        });
        return false;
      }
      if (e.target.value.length <= 0) {
        this.setState({
          givenNameError: "Required Field",
          givenNameisValid: false,
        });
        return false;
      } else if (e.target.value.length > 90) {
        this.setState({
          givenNameError:
            "Given Name is too long. Maximum length is 90 characters",
          givenNameisValid: false,
        });
        return false;
      } else this.setState({ givenNameisValid: true, givenNameError: "" });
      return true;
    }

    if (e.target.id === "surName") {
      if (!e.target.value.match(name)) {
        this.setState({
          surNameError: "No numbers or special characters allowed",
          surNameisValid: false,
        });
        return false;
      }
      if (e.target.value.length <= 0) {
        this.setState({
          surNameError: "Required Field",
          surNameisValid: false,
        });
        return false;
      } else if (e.target.value.length > 90) {
        this.setState({
          surNameError: "Surname is too long. Maximum length is 90 characters",
          surNameisValid: false,
        });
        return false;
      } else this.setState({ surNameisValid: true, surNameError: "" });
      return true;
    }
    if (e.target.id === "middleName") {
      if (!e.target.value.match(name)) {
        this.setState({
          middleNameError: "No numbers or special characters allowed",
          middleNameisValid: false,
        });
        return false;
      }
      if (e.target.value.length > 90) {
        this.setState({
          middleNameError:
            "Middle Name is too long. Maximum length is 90 characters",
          middleNameisValid: false,
        });
        return false;
      } else this.setState({ middleNameisValid: true, middleNameError: "" });
      return true;
    }
    if (e.target.id === "DateofB") {
      try {
        if (
          new Date().getFullYear() - e.target.valueAsDate.getFullYear() <
          15
        ) {
          this.setState({
            DateofBError: "Age must be over 15",
            DateofBisValid: false,
          });
          return false;
        }
        if (
          new Date().getFullYear() - e.target.valueAsDate.getFullYear() >
          110
        ) {
          this.setState({
            DateofBError: "Invalid Age",
            DateofBisValid: false,
          });
          return false;
        } else this.setState({ DateofBisValid: true, DateofBError: "" });
        return true;
      } catch (err) {
        this.setState({
          DateofBError: "Invalid date",
          DateofBisValid: false,
        });
        return false;
      }
    }

    // if (e.target.id === "EmpStDate") {
    //   if (!e.target.valueAsDate) {
    //     this.setState({
    //       EmpStDateError: "Invalid date",
    //       EmpStDateisValid: false,
    //     });
    //     return false;
    //   } else this.setState({ EmpStDateisValid: true, EmpStDateError: "" });
    //   return true;
    // }
    if (e.target.id === "LastEmpDate") {
      var today = new Date();
      if (today < new Date(e.target.value)) {
        this.setState({
          LastEmpDateisValid: false,
          LastEmpDateError: "Date cannot be greater than today",
        });
      } else
        this.setState({
          LastEmpDateisValid: true,
          LastEmpDateError: "",
        });
    }

    if (e.target.id === "mobileNumber") {
      if (
        !(
          e.target.value.match(phno) &&
          e.target.value.toString().startsWith("04")
        )
      ) {
        this.setState({
          mobileNumberError:
            "Please enter a valid 10 digit mobile phone number",
          mobileNumberisValid: false,
        });
        return false;
      } else
        this.setState({ mobileNumberisValid: true, mobileNumberError: "" });
      return true;
    }
    if (e.target.id === "alternativePhone") {
      if (
        e.target.value.length > 0 &&
        !(
          e.target.value.match(phno) &&
          e.target.value.toString().startsWith("04")
        )
      ) {
        this.setState({
          alternativePhoneError:
            "Please enter a valid 10 digit mobile phone number",
          alternativePhoneisValid: false,
        });
        return false;
      } else
        this.setState({
          alternativePhoneisValid: true,
          alternativePhoneError: "",
        });
      return true;
    }
    if (e.target.id === "LengthYears") {
      var years = /^\d{1,2}$/;
      if (e.target.value.length > 0 && !e.target.value.match(years)) {
        this.setState({
          LengthYearsError: "Please enter valid years",
          LengthYearsisValid: false,
        });
        return false;
      } else this.setState({ LengthYearsisValid: true, LengthYearsError: "" });
      return true;
    }
    if (e.target.id === "LengthMonths") {
      if (e.target.value.length > 0 && !(parseInt(e.target.value) <= 12)) {
        this.setState({
          LengthMonthsError: "Please enter valid month",
          LengthMonthsisValid: false,
        });
        return false;
      } else
        this.setState({ LengthMonthsisValid: true, LengthMonthsError: "" });
      return true;
    }
    if (e.target.id === "email") {
      var em = /^\S+@\S+\.\S+$/;

      if (!e.target.value.match(em)) {
        this.setState({
          emailError: "Invalid email",
        });
        return false;
      } else this.setState({ emailError: "" });
      return true;
    }

    if (e.target.id === "CompClaimDetails") {
      if (e.target.value.length <= 0) {
        this.setState({
          CompClaimDetailsError: "Required Field",
          CompClaimDetailsisValid: false,
        });
        return false;
      } else
        this.setState({
          CompClaimDetailsisValid: true,
          CompClaimDetailsError: "",
        });
      return true;
    }
    if (e.target.id === "PreviousJob") {
      if (!e.target.value.match(name)) {
        this.setState({
          previousJobError: "No numbers or special characters allowed",
          previousJobisValid: false,
        });
        return false;
      }
      if (e.target.value.length > 250) {
        this.setState({
          previousJobError:
            "Previous Job is too long. Maximum length is 250 characters",
          previousJobisValid: false,
        });
        return false;
      } else this.setState({ previousJobisValid: true, previousJobError: "" });
      return true;
    }
    if (e.target.id === "Employer") {
      if (!e.target.value.match(name)) {
        this.setState({
          employerError: "No numbers or special characters allowed",
          employerisValid: false,
        });
        return false;
      }
      if (e.target.value.length > 250) {
        this.setState({
          employerError:
            "Previous Job is too long. Maximum length is 250 characters",
          employerisValid: false,
        });
        return false;
      } else this.setState({ employerisValid: true, employerError: "" });
      return true;
    }

    if (e.target.id === "addressLine1") {
      if (e.target.value.length <= 0) {
        this.setState({
          addressLine1Error: "Required Field",
          addressLine1isValid: false,
        });
        return false;
      } else if (e.target.value.length > 250) {
        this.setState({
          addressLine1Error:
            "Address is too long. Maximum length is 250 characters",
          addressLine1isValid: false,
        });
        return false;
      } else
        this.setState({ addressLine1isValid: true, addressLine1Error: "" });
      return true;
    }
    if (e.target.id === "addressLine2") {
      if (e.target.value.length > 250) {
        this.setState({
          addressLine2Error:
            "Address is too long. Maximum length is 250 characters",
          addressLine1isValid: false,
        });
        return false;
      } else
        this.setState({ addressLine1isValid: true, addressLine2Error: "" });
      return true;
    }
    if (e.target.id === "suburb") {
      if (e.target.value.length <= 0) {
        this.setState({
          suburbError: "Required Field",
          suburbisValid: false,
        });
        return false;
      } else if (e.target.value.length > 40) {
        this.setState({
          suburbError: "Suburb is too long. Maximum length is 40 characters",
          suburbisValid: false,
        });
        return false;
      } else this.setState({ suburbisValid: true, suburbError: "" });
      return true;
    }

    if (e.target.id === "postCode") {
      if (
        !(Number(e.target.value) >= 200 && Number(e.target.value) <= 9999) ||
        !Number.isInteger(Number(e.target.value))
      ) {
        this.setState({
          postCodeError: "Invalid input",
          postCodeisValid: false,
        });
        return false;
      } else this.setState({ postCodeisValid: true, postCodeError: "" });
      return true;
    }
    if (e.target.id === "height") {
      if (!(Number(e.target.value) >= 50 && Number(e.target.value) <= 250)) {
        this.setState({
          heightError: "Invalid input",
          heightisValid: false,
        });
        return false;
      } else if (!Number.isInteger(Number(e.target.value))) {
        this.setState({
          heightError: "Please enter a whole number",
          heightisValid: false,
        });
        return false;
      } else this.setState({ heightisValid: true, heightError: "" });
      return true;
    }
    if (e.target.id === "weight") {
      if (!(Number(e.target.value) >= 20 && Number(e.target.value) <= 500)) {
        this.setState({
          weightError: "Invalid input",
          weightisValid: false,
        });
        return false;
      } else if (!Number.isInteger(Number(e.target.value))) {
        this.setState({
          weightError: "Please enter a whole number",
          weightisValid: false,
        });
        return false;
      } else this.setState({ weightisValid: true, weightError: "" });
      return true;
    }
  };

  validateStateCountry = (e, input) => {
    if (input === "stateName") {
      if (e.label.length <= 0) {
        this.setState({
          stateNameError: "Required Field",
          stateNameisValid: false,
        });
        return false;
      } else this.setState({ stateNameisValid: true, stateNameError: "" });
      return true;
    }

    if (input === "country") {
      if (e.label.length <= 0) {
        this.setState({
          countryError: "Required Field",
          countryisValid: false,
        });
        return false;
      } else this.setState({ countryisValid: true, countryError: "" });
      return true;
    }
  };

  prevStep = () => {
    const { step } = this.state;

    this.setState({
      step: step - 1,
    });
  };

  handleChange = (input) => (e) => {
    if (input === "culturalGroup") {
      this.setState({
        [input]: e,
      });

      var index = this.state.ethnicityoptions.findIndex(function (item, i) {
        return item.label === e.label;
      });

      this.setState({
        ethnicityCode: this.state.ethnicityoptions[index].value,
      });
    } else if (input === "stateName") {
      this.setState({
        [input]: e,
      });

      index = this.state.stateOpts.findIndex(function (item, i) {
        return item.label === e.label;
      });

      this.setState({
        stateCode: this.state.stateOpts[index].value,
      });

      this.validateStateCountry(e, input);
    } else if (input === "country") {
      this.setState({
        [input]: e,
      });

      index = this.state.Countryoptions.findIndex(function (item, i) {
        return item.label === e.label;
      });

      this.setState({
        countryCode: this.state.Countryoptions[index].value,
      });
      this.validateStateCountry(e, input);
    } else if (e.target) {
      var transfer = e.target.value;
      this.setState({
        [input]: transfer,
      });

      if (
        !(
          e.target.id === "titleOpt" ||
          e.target.id === "gender" ||
          e.target.id === "culturalGroup" ||
          // e.target.id === "CurrentPosition" ||
          e.target.id === "PreviousJob" ||
          //e.target.id === "Department" ||
          //e.target.id === "Employer" ||
          //e.target.id === "LineTask" ||
          // e.target.id === "CompClaim" ||
          e.target.id === "familyDoctor" ||
          e.target.id === "lastVisit" ||
          e.target.id === "reasonOfVisit" ||
          e.target.id === "handedness"
        )
      ) {
        this.validator(e);
      }
    } else {
      this.setState({
        [input]: e.value,
      });
      if (!(input === "culturalGroup")) {
        this.validateStateCountry(e, input);
      }
    }
  };

  showStep = (props) => {
    const { step } = this.state;

    if (step === 1)
      return (
        <PersonalDetails
          handleChange={this.handleChange}
          nextStep={this.nextStep}
          stepReset={this.stepReset}
          ethnicityCodef={this.ethnicityCodef}
          state={this.state}
          getdetails={this.getdetails}
          Leftarrow={this.Leftarrow}
        />
      );
    else if (step === 2)
      return (
        <ContactDetails
          handleChange={this.handleChange}
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          countryCodef={this.countryCodef}
          stateCodef={this.stateCodef}
          state={this.state}
        />
      );
    else if (step === 3)
      return (
        <JobDetails
          handleChange={this.handleChange}
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          state={this.state}
        />
      );

    // if (step === "abc")
    //   return (
    //     <Step4
    //       handleChange={this.handleChange}
    //       nextStep={this.nextStep}
    //       prevStep={this.prevStep}
    //       state={this.state}
    //       workflow={props.location.state.workflow}
    //     />
    //   );
  };

  successPage = () => {
    return <Brilliant state={this.state} handleChange={this.handleChange} />;
  };
  HomePage = () => {
    return (
      <Home
        loadingCircle={<IsLoading />}
        stepReset={this.stepReset}
        state={this.state}
        switchfunc={this.props.switchFunc}
        logout={this.props.logout}
      />
    );
  };

  History = () => {
    return <History state={this.state}></History>;
  };

  Formsprogress = () => {
    return <FormProgress state={this.state}></FormProgress>;
  };
  Splashscreen = () => {
    return (
      <SplashScreen
        state={this.state}
        logout={this.props.logout}
      ></SplashScreen>
    );
  };
  ReviewScreen = () => {
    return <ReviewScreen></ReviewScreen>;
  };
  Leftarrow = (hrefString) => {
    return (
      <div style={{ float: "left", marginTop: "4px" }}>
        <a href={hrefString}>
          <img
            src={require("../images/left-arrow-white.svg")}
            alt=""
            height="25px"
          />
        </a>
      </div>
    );
  };

  render() {
    return (
      <Router>
        <Suspense fallback={<IsLoading />}>
          <Switch>
            <Route exact path="/">
              <Loginpage loadingCircle={<IsLoading />} />
            </Route>
            <ProtectedRoute path="/Home" component={this.HomePage} />
            <Route path="/candidatepes">
              <Loginpage loadingCircle={<IsLoading />} />
            </Route>
            <ProtectedRoute
              path="/splashscreen"
              component={this.Splashscreen}
            />
            <ProtectedRoute
              path="/reviewscreen"
              component={this.ReviewScreen}
            />
            <Route path="/Signup">
              <Signup
                state={this.state}
                handleChange={this.handleChange}
                uuidset={this.uuidset}
                loadingCircle={<IsLoading />}
                resetForm={this.resetForm}
              />
            </Route>
            <Route path="/ForgotPassword">
              <ForgotPassword
                handleChange={this.handleChange}
                state={this.state}
                resetForm={this.resetForm}
              />
            </Route>

            <ProtectedRoute
              path="/formprogress"
              component={this.Formsprogress}
            />

            <ProtectedRoute
              path="/patientDetails"
              component={PersonalDetailsMain}
              Leftarrow={this.Leftarrow}
              loadingCircle={<IsLoading />}
            />
            <Route path="/success" component={this.successPage} />

            <ProtectedRoute path="/History" component={this.History} />

            <Switch>
              <ProtectedRoute path="/OreboModule" component={OreboModule} />

              <ProtectedRoute
                path="/painIndicator"
                Leftarrow={this.Leftarrow}
                component={BodyImageMain}
                loadingCircle={<IsLoading />}
              ></ProtectedRoute>

              <ProtectedRoute
                path="/CoreMedicalHistory"
                component={MedicalHistoryMain}
                Leftarrow={this.Leftarrow}
                loadingCircle={<IsLoading />}
              ></ProtectedRoute>
              <ProtectedRoute
                path="/pesJob"
                component={PESJobDetails}
                Leftarrow={this.Leftarrow}
                loadingCircle={<IsLoading />}
              ></ProtectedRoute>
              <ProtectedRoute
                path="/pesFoodQ"
                component={FoodHandlersQuestionnaire}
                Leftarrow={this.Leftarrow}
                loadingCircle={<IsLoading />}
              ></ProtectedRoute>
              <ProtectedRoute
                path="/CorePsychologicalModule"
                Leftarrow={this.Leftarrow}
                loadingCircle={<IsLoading />}
                component={CorePsychologicalModule}
              ></ProtectedRoute>

              <ProtectedRoute
                path="/RedFlagModule"
                loadingCircle={<IsLoading />}
                Leftarrow={this.Leftarrow}
                component={RedFlagModule}
              ></ProtectedRoute>

              <ProtectedRoute
                path="/CoreLifeStyleModule"
                loadingCircle={<IsLoading />}
                Leftarrow={this.Leftarrow}
                component={CoreLifeStyleModule}
              ></ProtectedRoute>

              <ProtectedRoute path="/ManualHandling">
                <ManualHandling state={this.state} />
              </ProtectedRoute>

              <ProtectedRoute path="/IndustrySpecificModule">
                <IndustrySpecificModule state={this.state} />
              </ProtectedRoute>
              <ProtectedRoute path="/AdditionalMedicalHistory">
                <AdditionalMedicalHistory state={this.state} />
              </ProtectedRoute>
              <ProtectedRoute
                path="/FamilyHistoryModule"
                loadingCircle={<IsLoading />}
                Leftarrow={this.Leftarrow}
                component={FamilyHistoryModule}
              ></ProtectedRoute>

              <ProtectedRoute
                path="/NDSModule"
                loadingCircle={<IsLoading />}
                Leftarrow={this.Leftarrow}
                component={NDSModule}
              ></ProtectedRoute>

              <ProtectedRoute
                path="/QuebecModule"
                loadingCircle={<IsLoading />}
                Leftarrow={this.Leftarrow}
                component={QuebecModule}
              ></ProtectedRoute>

              <ProtectedRoute
                path="/LEFSModule"
                loadingCircle={<IsLoading />}
                Leftarrow={this.Leftarrow}
                component={LEFSModule}
              ></ProtectedRoute>

              <ProtectedRoute
                path="/PSSModule"
                loadingCircle={<IsLoading />}
                Leftarrow={this.Leftarrow}
                component={PSSModule}
              ></ProtectedRoute>

              <ProtectedRoute
                path="/FABQMain"
                loadingCircle={<IsLoading />}
                Leftarrow={this.Leftarrow}
                component={FABQMain}
              ></ProtectedRoute>

              <ProtectedRoute
                path="/FOSQModule"
                loadingCircle={<IsLoading />}
                Leftarrow={this.Leftarrow}
                component={FOSQModule}
              ></ProtectedRoute>

              <ProtectedRoute
                path="/PainScaleModule"
                loadingCircle={<IsLoading />}
                Leftarrow={this.Leftarrow}
                component={PainScaleModule}
              ></ProtectedRoute>

              <ProtectedRoute
                path="/DASSModule"
                loadingCircle={<IsLoading />}
                Leftarrow={this.Leftarrow}
                component={DASSModule}
              ></ProtectedRoute>

              <ProtectedRoute
                path="/DASHModule"
                loadingCircle={<IsLoading />}
                Leftarrow={this.Leftarrow}
                component={DASHModule}
              ></ProtectedRoute>

              <ProtectedRoute
                path="/MusculoskeletonModule"
                loadingCircle={<IsLoading />}
                Leftarrow={this.Leftarrow}
                component={MusculoskeletonModule}
              ></ProtectedRoute>
            </Switch>
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

export default withRouter(Main);
